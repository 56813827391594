import { Button, Tab, Tabs } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { AddOutlined, Logout, NorthEastOutlined, NorthWestOutlined, VisibilityOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import bgimg from '../../assets/images/bgimg.png';
import bgimgcar from '../../assets/images/car-lucid.png';
import gtrImage from '../../assets/images/x7g-01.png';
import bgimgcarChange from '../../assets/images/bgcaraccessories.png';
import bgimgcarChange2 from '../../assets/images/bgcartop.png';

import car1 from '../../assets/images/lucid-floor-black.png';
import car2 from '../../assets/images/lucid-floor-brown.png';
import car3 from '../../assets/images/lucid-sunshades.png';


import wheel4 from '../../assets/images/lucid-tyres.png';
import wheel5 from '../../assets/images/lucid-bike-stand.png';
import wheel6 from '../../assets/images/lucid-home-charger.png';

import p1 from '../../assets/images/wheel/performanceimg1.png';
import p2 from '../../assets/images/wheel/performanceimg2.png';
import p3 from '../../assets/images/wheel/performanceimg3.png';

import panoramuic from '../../assets/images/Leather Dashboard $900.webp'
import tailgate from '../../assets/images/Second-Row Captains Chairs $850.webp'
import heatedFrontSteering from '../../assets/images/5-zone automatic climate control $800.webp'
import heatedSteering from '../../assets/images/Bowers & Wilkins Diamond Surround Sound System $3400.webp'
import harmanKardon from '../../assets/images/Crystal Headlights $2100.webp'
import cruise from '../../assets/images/Glass-Controls-$650.webp'

import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import { useLocation } from 'react-router-dom';

const VehicleAddOns = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')
    const link = `${location.pathname}${location.search}`

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);

    const [imageSrc, setImageSrc] = useState(gtrImage);
    const [isImage1, setIsImage1] = useState(true);
    const handleImageToggle = () => {
        if (isImage1) {
            setImageSrc(gtrImage);
        } else {
            setImageSrc(bgimgcarChange);
        }
        setIsImage1(!isImage1);
    };


    const handleImageChange = () => {
        setImageSrc(bgimgcarChange2);
    };


    return (
        <div>
            <div className="digital_checkout_wrapper">
                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">
                        <div className="po_header pb-4">
                            <div className="d-flex justify-content-end align-items-center">
                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>
                        <div className='tradeoptions_content po_content_height container'>
                            <div className='row'>
                                <div className='col-6 col-xxl-7 position-relative'>
                                    <div className='mg_vehadon_left'>
                                        <div className='po_headlines'>
                                            <h3 className='headline-5 light pb-1'>Vehicle Add-Ons</h3>
                                            <p className='label-2 light pb-3'>View and add accessories. These will be applied to your total price.</p>
                                        </div>
                                        <div className='position-relative '>
                                            <div className='mg_position-carimg mg_left-img'>
                                                <img src={imageSrc} alt="Image" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 col-xxl-5 pe-0'>
                                    <div className='row'>
                                        <div className='col-12 mg_tabs'>
                                            {/* <Tabs defaultActiveKey="ExteriorAccesories" className='pfi_filled_tabs pfi_filled_white_tabs' fill>
                                                <Tab eventKey="ExteriorAccesories" title="Exterior Accesories">
                                                </Tab>
                                                <Tab eventKey="InteriorAccesories" title="Interior Accesories">
                                                </Tab>
                                            </Tabs> */}

                                        </div>
                                        <div className='col-12 mb-3'>
                                            <p className='headline-6 mb-2 pt-2 fw-normal'>Add-Ons</p>
                                            <ul className='d-flex flex-wrap mg_transport-list'>
                                                <li>
                                                    <div className='position-relative'>
                                                        <i className='mg_VehicleImg-position'> <img src={panoramuic} alt='#'></img></i>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>Leather Dashboard</p>
                                                        <span>$ 900</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='position-relative'>
                                                        <i className='mg_VehicleImg-position'>  <img src={tailgate} alt='#'></img></i>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>Second Row Captain's Chairs </p>
                                                        <span>$ 850</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='position-relative'>
                                                        <img style={{ height: "165px" }} src={heatedFrontSteering} alt='#'></img>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>5-Zone Automatic Climate Control</p>
                                                        <span>$ 800</span>

                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className='col-12 mb-3'>
                                            {/* <p className='headline-6 fw-normal mb-2'>Exterior Accessories</p> */}
                                            <ul className='d-flex flex-wrap mg_transport-list'>
                                                <li>
                                                    <div className='position-relative'>
                                                        <i className='mg_VehicleImg-position'>  <img src={heatedSteering} alt='#'></img></i>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>Bowers & Wilkins Diamond Surround Sound</p>
                                                        <span>$ 3,400</span>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='position-relative'>
                                                        <i className='mg_VehicleImg-position'>   <img src={harmanKardon} alt='#'></img></i>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>Crystal Headlights </p>
                                                        <span>$ 2,100</span>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='position-relative'>
                                                        <i className='mg_VehicleImg-position'> <img src={cruise} alt='#'></img></i>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>Glass Controls</p>
                                                        <span>$ 650</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div className='col-12 mb-3'>
                                            <p className='headline-6 fw-normal mb-2'>BMW M Performance</p>
                                            <ul className='d-flex flex-wrap mg_transport-list'>
                                                <li>
                                                    <div className='position-relative'>
                                                        <i className='mg_VehicleImg-position'>   <img src={p1} alt='#'></img></i>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>BMW M Performance Side Skirts in Black High-Gloss</p>
                                                        <span>$9</span>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='position-relative'>
                                                        <i className='mg_VehicleImg-position'> <img src={p2} alt='#'></img></i>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>BMW M Performance Carbon Fiber Mirror Caps</p>
                                                        <span>$926</span>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='position-relative'>
                                                        <i className='mg_VehicleImg-position'> <img src={p3} alt='#'></img></i>
                                                        <div className='d-flex justify-content-center mg_positionPill'>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageToggle}>  <VisibilityOutlined /> Visualize</button>
                                                            <button type="button" className="btn btn-outline-primary border-0 mg_pill-btn" onClick={handleImageChange}> <AddOutlined /> Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column mg_vehiclelist-Content py-2'>
                                                        <p>BMW M Performance Rear Spoiler in Carbon Fiber</p>
                                                        <span>$632</span>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>

                                </div>

                            </div>






                        </div>
                        <DigitalCheckoutFooter pageUrl={`/WarrantyAndProtectionPlans?orderId=${orderID}&customerRefId=${customerRefId}`} />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default VehicleAddOns;