import { Button, Form, OverlayTrigger } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from "@mui/material";

import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import { AccessTime, CheckCircle, ErrorOutline, FileCopy, FileDownload, LightbulbOutlined, Logout } from '@mui/icons-material';
import bd_tradin_badge from '../../assets/images/kelley_blueBook.png';
import slide1img from '../../assets/images/fit_model_img1.png';
import frontImg from '../../assets/images/license_front.png';
import backImg from '../../assets/images/license_back.png';

const Contracting = () => {
    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);
    return (
        <div>
            <div className="digital_checkout_wrapper contracting_main">

                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">

                        <div className="po_header pb-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <span className='content-3 border v_time_btn me-2 d-flex align-items-center pe-3'>
                                    <AccessTime className='me-2' />
                                    <small className='text-center'>
                                        Vehicle Reserved
                                        <b className='bold d-block'>00:36:05</b>
                                    </small>
                                </span>
                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>

                        <div className='rd_content po_content_height'>
                            <div className='po_headlines'>
                                <h3 className='headline-5 regular pb-1'>LET'S MAKE SURE EVERYTHING'S RIGHT</h3>
                                <p className='label-2 light pb-4'>Your new BMW is just moments away.</p>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 regular'><span className="text-secondary pe-3">01</span> Your Deal</h3>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='border'>
                                            <div className="bd_box rounded-0 c_dark_box">
                                                <div >
                                                    <h2 className="headline-5 bold mb-1 text-white">2023 BMW 330i xDrive Sedan</h2>
                                                </div>
                                                <div className='c_dark_box_inner d-flex align-items-end justify-content-between flex-wrap'>
                                                    <div>
                                                        <h3 className='headline-5 bold text-white'>$585<small className='headline-6 regular ms-2'>/ month</small></h3>
                                                        <h3 className='headline-5 bold text-white'>$7,495<small className='headline-6 regular ms-2'>Due at signing (Dec 22)</small></h3>
                                                        <span className='label-1 bold d-block mb-2 mt-4 text-white'>PAYMENT TERMS</span>
                                                        <ul>
                                                            <li className='label-1 text-white mb-1'>36-Month Term</li>
                                                            <li className='label-1 text-white mb-1'>$5,000 Down Payment</li>
                                                            <li className='label-1 text-white mb-1'>2,000 Miles Per Year</li>
                                                        </ul>
                                                    </div>
                                                    <div className="c_dark_box_img">
                                                        <img src={slide1img} alt="#" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bd_box contracting_box'>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='col-12 mb-4'>
                                                        <h3 className='content-2--bold'>Estimated Trade-In Value</h3>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <div className='bd_tradin_sec p-0'>
                                                            <div className='bd_tradin_left d-flex flex-wrap align-items-center'>
                                                                <div className='bd_tradin_img me-4'>
                                                                    <img src={bd_tradin_badge} alt='#' />
                                                                </div>
                                                                <div className='bd_tradin_text'>
                                                                    <h3 className='label-1 regular'>2001 BMW 330ci Convertible</h3>
                                                                    <span className='content-3--regular-grey'>Guaranteed by Kelly Blue Book, Instant Cash Offer</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col ps-2 text-end'>
                                                        <h3 className='content-2--bold'>-$5,000</h3>
                                                    </div>
                                                </div>
                                                <div className="filter_options_divider mt-4 mb-4"></div>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='col-12 mb-3'>
                                                        <h3 className='content-2--bold'>Incentives & Discounts</h3>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <span className='content-3--regular-grey'>Loyalty Credit</span>
                                                    </div>
                                                    <div className='col ps-2 text-end'>
                                                        <h3 className='content-2--bold'>-$750</h3>
                                                    </div>
                                                </div>
                                                <div className="filter_options_divider mt-4 mb-4"></div>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='col-12 mb-3'>
                                                        <h3 className='content-2--bold'>Accessories</h3>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <span className='content-3--regular-grey'>BMW Rubber Floor Liners</span>
                                                    </div>
                                                    <div className='col ps-2 text-end'>
                                                        <h3 className='content-2--bold'>-$150</h3>
                                                    </div>
                                                </div>
                                                <div className="filter_options_divider mt-4 mb-4"></div>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='col-12 mb-3'>
                                                        <h3 className='content-2--bold'>Coverage & Protection</h3>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <span className='content-3--regular-grey'>Tire & Wheel</span>
                                                    </div>
                                                    <div className='col ps-2 text-end'>
                                                        <h3 className='content-2--bold'>-$600</h3>
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='col-md-8'>
                                                        <span className='content-3--regular-grey'>Dent Protection</span>
                                                    </div>
                                                    <div className='col ps-2 text-end'>
                                                        <h3 className='content-2--bold'>-$600</h3>
                                                    </div>
                                                </div>
                                                <div className="filter_options_divider mt-4 mb-4"></div>
                                                <div>
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-8'>
                                                            <h3 className='content-2--bold'>Total Cost of Lease</h3>
                                                        </div>
                                                        <div className='col ps-2 text-end'>
                                                            <h3 className='content-2--bold'>$54,670</h3>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2">MSRP</span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey mb-0">$48,670</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2">BMW "Road Home" Incentive</span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">-$500</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Cash Down
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">-$5,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="filter_options_divider mt-2 mb-3"></div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2">Trade-in Value</span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">-$5,000</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Incentives & Discount</span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$750</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Additional Accessories
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$152</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Protection and Coverage
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$1,200</span>
                                                        </div>
                                                    </div>
                                                    <div className="filter_options_divider mt-3 mb-3"></div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Dealer adjustments
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$2,000</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Dealer-Installed Accessories
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$2,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="filter_options_divider mt-3 mb-3"></div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Taxes & Fees (ZIP: 10003)
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$1,500</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='d-flex mb-3 mt-4'>
                                                            <div className='col-md-8'>
                                                                <h3 className='content-2--bold'>Due at Signing</h3>
                                                            </div>
                                                            <div className='col ps-2 text-end'>
                                                                <h3 className='content-2--bold'>$7,495</h3>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-8 pb-2'>
                                                                <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Down Payment
                                                                </span>
                                                            </div>
                                                            <div className='col-md-4 pb-2 text-end'>
                                                                <span className="content-3--regular-grey  mb-0">$5,000</span>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-8 pb-2'>
                                                                <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">First Month's Lease Installment
                                                                </span>
                                                            </div>
                                                            <div className='col-md-4 pb-2 text-end'>
                                                                <span className="content-3--regular-grey  mb-0">$700</span>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-8 pb-2'>
                                                                <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Delevery Fees
                                                                </span>
                                                            </div>
                                                            <div className='col-md-4 pb-2 text-end'>
                                                                <span className="content-3--regular-grey  mb-0">$150</span>
                                                            </div>
                                                        </div>
                                                        <div className="filter_options_divider mt-3 mb-3"></div>
                                                        <p className='label-2'>Subsequent monthly payments will be deducted on the 16th of each month.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <p className='bd_alert_text mt-4 content-3--regular-grey'>
                                            <LightbulbOutlined />
                                            Here are the details of your deal. This summary includes any trade-in credits, incentives and discounts, accessories, and coverage and protection products.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 regular'><span className="text-secondary pe-3">02</span> Financing</h3>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='bd_box border contracting_box'>
                                            <div className='d-flex flex-wrap'>
                                                <div className='col-12 mb-4'>
                                                    <h3 className='content-2--bold'>Your Purchase Type</h3>
                                                </div>
                                                <div className='col-12'>
                                                    <h3 className='content-2--bold'>This Vehicle if for you</h3>
                                                    <p className='content-3--regular-grey'>You are the primary driver. Other drivers may use the vehicle occasionally.</p>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Your Information</h3>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>First Name</strong>
                                                    <strong className='label-1 d-block'>Sarah</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Last Name</strong>
                                                    <strong className='label-1 d-block'>Schumacher</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Date of Birth</strong>
                                                    <strong className='label-1 d-block'>December 10, 1981</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Social Security Number</strong>
                                                    <strong className='label-1 d-block'>*** *** 1234</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Phone Number</strong>
                                                    <strong className='label-1 d-block'>(555) 555-3333</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Email Address</strong>
                                                    <strong className='label-1 d-block'>sarah.schumacher@bmwna.com</strong>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Your Current Address</h3>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Current Address</strong>
                                                    <strong className='label-1 d-block'>822 Sarbonne Rd Los Angeles 90077</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Duration of Stay</strong>
                                                    <strong className='label-1 d-block'>2 Years</strong>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Your Previous Address</h3>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Previous Address</strong>
                                                    <strong className='label-1 d-block'>610 Varik Street New York, NY10454</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Duration of Stay</strong>
                                                    <strong className='label-1 d-block'>2 Years</strong>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Your Employment & Income Information</h3>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Employer Name</strong>
                                                    <strong className='label-1 d-block'>Sarah Schumacher</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Employer Address</strong>
                                                    <strong className='label-1 d-block'>610 Varik Street New York, NY10454</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Occupation</strong>
                                                    <strong className='label-1 d-block'>Senior Marketing Director</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Employment Type</strong>
                                                    <strong className='label-1 d-block'>Full Time</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Annual Income</strong>
                                                    <strong className='label-1 d-block'>$999,999</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Duration of Employment</strong>
                                                    <strong className='label-1 d-block'>4 years</strong>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Your Debit & Financial Obligations</h3>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Residence Status</strong>
                                                    <strong className='label-1 d-block'>Other</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Monthly Payments</strong>
                                                    <strong className='label-1 d-block'>$5,000</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Have You Ever Applied for Bankruptcy</strong>
                                                    <strong className='label-1 d-block'>No</strong>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Your Identification</h3>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <div className='c_doc_box border'>
                                                        <span className='label-2 bold'>
                                                            <FileCopy className='me-3' />
                                                            Document_01
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='c_doc_box border'>
                                                        <span className='label-2 bold'>
                                                            <FileCopy className='me-3' />
                                                            Document_02
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <p className='bd_alert_text mt-4 content-3--regular-grey'>
                                            <LightbulbOutlined />
                                            Please review your financing information for accuracy.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 regular'><span className="text-secondary pe-3">03</span> Insurance</h3>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='bd_box border contracting_box'>
                                            <div className='d-flex flex-wrap'>
                                                <div className='col-12 mb-4'>
                                                    <h3 className='content-2--bold'>Your Driver's License</h3>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='ins_uploadLicense_row d-flex align-items-center'>
                                                        <div className='col'>
                                                            <div className='ins_licenseImgBox'>
                                                                <img src={"https://unity-generated-documents.s3.amazonaws.com/BiSqNIkCSZ.png"} alt='#'></img>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='ins_licenseImgBox'>
                                                                <img src={"https://unity-generated-documents.s3.amazonaws.com/NGISYUDYDX.png"} alt='#'></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Your Garaging Address</h3>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Garaging Address</strong>
                                                    <strong className='label-1 d-block'>610 Varik Street New York, NY10454</strong>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Your Insurance Information</h3>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Insurance Provider</strong>
                                                    <strong className='label-1 d-block'>Start Farm Insurance</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Vehicle Make</strong>
                                                    <strong className='label-1 d-block'>Volvo</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Insured Driver</strong>
                                                    <strong className='label-1 d-block'>Sarah Schumacher</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Vehicle Year</strong>
                                                    <strong className='label-1 d-block'>2020</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Policy Number</strong>
                                                    <strong className='label-1 d-block'>9896-5546-7689-9865</strong>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <strong className='label-2 bold d-block'>Vehicle Model</strong>
                                                    <strong className='label-1 d-block'>XC60</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>Effective Dates</strong>
                                                    <strong className='label-1 d-block'>Jan. 30, 2019 thru Jan. 30, 2022</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                    <strong className='label-2 bold d-block'>VIN</strong>
                                                    <strong className='label-1 d-block'>3MW5R7</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <p className='bd_alert_text mt-4 content-3--regular-grey'>
                                            <LightbulbOutlined />
                                            Here's the insurance information you provided. Just a reminder, your garaging address should be the address you vehicle will be parked at most of the time.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 regular'><span className="text-secondary pe-3">04</span> Delivery & Payment</h3>
                                        <Button className="px-3 py-2" variant="outline-secondary">Edit</Button>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='bd_box border contracting_box'>
                                            <div className='d-flex flex-wrap'>
                                                <div className='col-12 mb-4'>
                                                    <h3 className='content-2--bold'>Your Scheduled Delivery</h3>
                                                    <p className='label-2 light'>You can reschedule this delivery window in your Shopping Hub</p>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='dp_delivery'>
                                                        <div className='row align-items-center'>
                                                            <div className='col-md-9 d-flex flex-wrap'>
                                                                <div className='fp_step3_date rounded-1 overflow-hidden border'>
                                                                    <small>DEC</small>
                                                                    <b>22</b>
                                                                </div>
                                                                <div className='fp_step3_address'>
                                                                    <h3 className='content-2--bold'>Delivery</h3>
                                                                    <p className='content-3--regular-grey'>200 Varick Street Suite 610 New York, NY 10454<br /> Dec. 8, 2022 at <b className='bold'>8:00 AM - 9:30 AM</b></p>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3 text-end'>
                                                                <h3 className='content-2--bold'>+$150</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter_options_divider mt-4 mb-4"></div>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <h3 className='content-2--bold'>Payment Details</h3>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="d-flex mb-3 mt-2">
                                                        <div className="col-md-8">
                                                            <h3 className="headline-6 bold">Due at Signing</h3>
                                                        </div>
                                                        <div className="col ps-2 text-end">
                                                            <h3 className="headline-6 bold">$7,495</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">
                                                                Reservation Credit (24hrs)
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">-$250</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">
                                                                Down Payment
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$5,000</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">
                                                                First Month's Lease Installment
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$595</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">
                                                                Delivery Fees
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$150</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">State Taxes
                                                                <span className='msrp_tip'>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip className='tooltip_box tooltip_box_top'>Lorem ipsum dolor sit amet dummy text Lorem ipsum dolor sit amet dummy textLorem ipsum dolor sit amet dummy text</Tooltip>}
                                                                    >
                                                                        <i className='help_icon ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$1,400</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-8 pb-2'>
                                                            <span className="content-3--regular-grey mb-0 me-2 d-flex align-items-center">Other Fees
                                                                <span className='msrp_tip'>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip className='tooltip_box tooltip_box_top'>Lorem ipsum dolor sit amet dummy text Lorem ipsum dolor sit amet dummy textLorem ipsum dolor sit amet dummy text</Tooltip>}
                                                                    >
                                                                        <i className='help_icon ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4 pb-2 text-end'>
                                                            <span className="content-3--regular-grey  mb-0">$600</span>
                                                        </div>
                                                    </div>
                                                    <div className='row pt-2'>
                                                        <div className='col-md-8 pb-3'>
                                                            <p className='label-2'>Subsequent monthly payments will be deducted on the 16th of each month.</p>
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <p className='label-2'><b className='bold'>Disclaimer:</b> Your card will not be changed until your local BMW Center signs your contract. Your banking information will only be held thur the day of delivery.</p>
                                                        </div>
                                                    </div>
                                                    <div className="filter_options_divider mt-4 mb-4"></div>
                                                    <div className='row'>
                                                        <div className='col-md-6 mb-3'>
                                                            <strong className='label-2 bold d-block'>Bank Name</strong>
                                                            <strong className='label-1 d-block'>Capital One 360</strong>
                                                        </div>
                                                        <div className='col-md-6 mb-3'>
                                                            <strong className='label-2 bold d-block'>Branck Name</strong>
                                                            <strong className='label-1 d-block'>Yonge and Avondale</strong>
                                                        </div>
                                                        <div className='col-md-6 mb-3'>
                                                            <strong className='label-2 bold d-block'>Account Number</strong>
                                                            <strong className='label-1 d-block'>5136782</strong>
                                                        </div>
                                                        <div className='col-md-6 mb-3'>
                                                            <strong className='label-2 bold d-block'>Financial Institution Code</strong>
                                                            <strong className='label-1 d-block'>004</strong>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <strong className='label-2 bold d-block'>Swift Number</strong>
                                                            <strong className='label-1 d-block'>NFBKU33XXX</strong>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <strong className='label-2 bold d-block'>Routing Number</strong>
                                                            <strong className='label-1 d-block'>000419234</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <p className='bd_alert_text mt-4 content-3--regular-grey'>
                                            <LightbulbOutlined />
                                            If you need to change when or how you receive your vehicle, you can do so in your Shopping Hub.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 regular'><span className="text-secondary pe-3">05</span> Contracting</h3>
                                        <Button variant="outline" className='border'>Download Signed Documents</Button>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='bd_box border contracting_box'>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <div className='c_doc_box border d-flex flex-wrap align-items-center justify-content-between'>
                                                        <div className='pe-3 d-flex'>
                                                            <FileCopy className='me-3' />
                                                            <span className='label-2 bold'>
                                                                Retail Installment Sales Contract
                                                                <small className='label-2 regular d-block'>4 min read</small>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className='label-2 bold d-flex align-items-center'>
                                                                Signed
                                                                <CheckCircle className='ms-2 text-primary' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <div className='c_doc_box border d-flex flex-wrap align-items-center justify-content-between'>
                                                        <div className='pe-3 d-flex'>
                                                            <FileCopy className='me-3' />
                                                            <span className='label-2 bold'>
                                                                Limited Warranty Agreement
                                                                <small className='label-2 regular d-block'>4 min read</small>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className='label-2 bold d-flex align-items-center'>
                                                                Signed
                                                                <CheckCircle className='ms-2 text-primary' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <div className='c_doc_box border d-flex flex-wrap align-items-center justify-content-between'>
                                                        <div className='pe-3 d-flex'>
                                                            <FileCopy className='me-3' />
                                                            <span className='label-2 bold'>
                                                                Financial Privacy Policy
                                                                <small className='label-2 regular d-block'>4 min read</small>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className='label-2 bold d-flex align-items-center'>
                                                                Signed
                                                                <CheckCircle className='ms-2 text-primary' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <div className='c_doc_box border d-flex flex-wrap align-items-center justify-content-between'>
                                                        <div className='pe-3 d-flex'>
                                                            <FileCopy className='me-3' />
                                                            <span className='label-2 bold'>
                                                                Vehicle Title Reassignment
                                                                <small className='label-2 regular d-block'>4 min read</small>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className='label-2 bold d-flex align-items-center'>
                                                                Signed
                                                                <CheckCircle className='ms-2 text-primary' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row pt-3 contracting_download'>
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col-md-9 content-3--regular-grey d-flex align-items-center'>
                                                            <ErrorOutline className='me-2' />
                                                            <p className='content-3--regular-grey'>
                                                                There are 5 additional documents that will require an in-person signature at the time of delivery:
                                                            </p>
                                                        </div>
                                                        <div className='col-md-3 d-flex justify-content-end contracting_download_btn'>
                                                            <Button variant='secondary' className='px-3'><FileDownload className='me-3' />Download</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 pt-3'>
                                                    <ul>
                                                        <li className='content-3--regular-grey'>Donec sed odio dui</li>
                                                        <li className='content-3--regular-grey'>Fasce dopibus llus ac cursus commodo</li>
                                                        <li className='content-3--regular-grey'>Vivamus sagittis lacus vel</li>
                                                        <li className='content-3--regular-grey'>Tellus ac cursus commodo</li>
                                                        <li className='content-3--regular-grey'>Duis mollis, est non commodo</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <p className='bd_alert_text mt-4 content-3--regular-grey'>
                                            <LightbulbOutlined />
                                            Just hit “Download Signed Documents” if you’d like your own copies of these contract documents.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <p className='content-3--regular-grey'>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing eit, sed do eiusmod tempor incididunt ut labore et dolore magna aligua. Ut enim ad minim veniam, quis
                                            nostrud exercitation ulomco laboris nisi ut aliquip ex ea commodo consequat. Dus aut irure dolor in reprehenderit in voluptate velit esse cilum dolore eu fugiat
                                            nulla pariatur. Excepteur sint occoecat cupidatat non proident, sunt in culpa qui officio deserunt mit anim id est laborum Lorem ipsum dolor sit amet, consectetur
                                            adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore mogna aliquo. Ut enim ad minim veniom, quis nostrud exercitaion ulomco laboris nisi ut
                                            aliquip ex ea commodo consequat. Dus aute irure dolor in reprehenderit i voluptate velit esse cilum dolore eu fugiot nulla poriatur. Excepteur sint occoecat
                                            cupidatat non proident, sunt in culpa qui ofica deserunt molit anim ic est laborum.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <DigitalCheckoutFooter pageUrl='/EndingPage' />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default Contracting;