import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import React, { useState } from 'react';

import img from '../../assets/images/svh_pop_img.png';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIos from '@mui/icons-material/ChevronRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import fit_model_img1 from '../../assets/images/fit_model_img1.png';

const FinalizeInperson = ({ showModal, handleCloseModal }) => {
    const [isNextStep, setIsNextStep] = useState({
        id: 'step1',
    })
    const continueHandleClick = (e) => {
        setIsNextStep({
            id: e,
        })
    }

    return (
        <div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" className='finalizeInPersonModal modal_has_leftImg'>
                <Modal.Header closeButton onClick={(e) => { continueHandleClick("step1") }}>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    {/* Finalize In-Person Modal step 1 */}
                    {
                        isNextStep.id === 'step1' ?
                            <div className='fp_modal fp_step1'>
                                <div className="row m-0">
                                    <div className="col-5 p-0">
                                        <div className="mg_modal-left position-relative">
                                            <img src={img} alt="#"></img>
                                            <div className="mg_modal-left-content position-absolute">
                                                <h1>Continue Your Order In-Person</h1>
                                                <di className="fp_modal_left_text">
                                                    <span className='label-1 d-block mb-2 bold text-white'>BMW of Manhattan</span>
                                                    <p>555 W 57th, New York, NY10019</p>
                                                    <p>(212) 586-2269 <Link className='text-white'>www.bwmnyc.com</Link></p>
                                                </di>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-7 p-0">
                                        <div className='fp_right'>
                                            <div className='fp_right_head mb-4'>
                                                <p className='label-1'>Come into your local BMW center to conitnue your application with help from a BMW expert.</p>
                                            </div>
                                            <div className='fp_right_content'>
                                                <div className='fp_step1_box'>
                                                    <div className='fp_step1_box_row pb-2 mb-3 border-bottom d-flex'>
                                                        <div className='fp_step1_box_heading pe-3 w-50'>
                                                            <div className="content-3--regular-grey">VIN# z</div>
                                                            <h6 className='headline-6 bold'>2023 BMW 330i xDrive Sedon</h6>
                                                        </div>
                                                        <div className='fp_step1_box_img ps-2 w-50'>
                                                            <img src={fit_model_img1} alt="#" />
                                                        </div>
                                                    </div>
                                                    <div className='fp_step1_box_row d-flex'>
                                                        <div className='fp_step1_box_heading pe-3'>
                                                            <div className="content-3--regular-grey d-flex align-items-center">
                                                                ESTIMATED PAYMENT
                                                                <span className='msrp_tip'>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip className='tooltip_box tooltip_box_top'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Tooltip>}
                                                                    >
                                                                        <i className='help_icon'></i>
                                                                    </OverlayTrigger>
                                                                </span>
                                                            </div>
                                                            <h6 className='headline-6 bold'>$585<span>/month</span></h6>
                                                        </div>
                                                        <div className='fp_step1_box_heading ps-3'>
                                                            <div className="content-3--regular-grey d-flex align-items-center">
                                                                TOTAL PRICE
                                                                <span className='msrp_tip'>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip className='tooltip_box tooltip_box_top'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</Tooltip>}
                                                                    >
                                                                        <i className='help_icon'></i>
                                                                    </OverlayTrigger>
                                                                </span>
                                                            </div>
                                                            <h6 className='headline-6 bold'>$54,670</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='fp_right_footer d-flex align-items-center'>
                                                <Button className="d-flex justify-content-between align-items-center me-1 w-50" onClick={(e) => { continueHandleClick("step2") }} variant="primary">Select Date & Time <ArrowForwardIos /></Button>
                                                <span className='ps-2 w-50'>Next Availabel window:<br /> 4PM - 7PM Today</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }

                    {
                        isNextStep.id === 'step2' ?
                            <div className='fp_modal fp_step2'>
                                <div className="row m-0">
                                    <div className="col-5 p-0">
                                        <div className="mg_modal-left position-relative">
                                            <img src={img} alt="#"></img>
                                            <div className="mg_modal-left-content position-absolute">
                                                <h1>Continue Your Order In-Person</h1>
                                                <di className="fp_modal_left_text">
                                                    <span className='label-1 d-block mb-2 bold text-white'>BMW of Manhattan</span>
                                                    <p>555 W 57th, New York, NY10019</p>
                                                    <p>(212) 586-2269 <Link className='text-white'>www.bwmnyc.com</Link></p>
                                                </di>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-7 p-0">
                                        <div className='fp_right'>
                                            <div className='fp_right_head mb-4'>
                                                <p className='label-1'>Come into your local BMW center to conitnue your application with help from a BMW expert.</p>
                                            </div>
                                            <div className='fp_right_content'>
                                                <div className='fp_date'>
                                                    <div className='fp_step1_box_heading mb-2'>
                                                        <div className="content-3--regular-grey">Select Date & Time</div>
                                                    </div>
                                                    <div className='fp_date_box position-relative border mb-3'>
                                                        <div className='fp_date_box_inner'>
                                                            <ul className='d-flex'>
                                                                <li>
                                                                    <button type='button' className='fp_date_btn active'>
                                                                        <span>Sun</span>
                                                                        19
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button type='button' className='fp_date_btn'>
                                                                        <span>Mon</span>
                                                                        20
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button type='button' className='fp_date_btn'>
                                                                        <span>Tue</span>
                                                                        21
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button type='button' className='fp_date_btn'>
                                                                        <span>We</span>
                                                                        22
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button type='button' className='fp_date_btn'>
                                                                        <span>Th</span>
                                                                        23
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='fp_date_next'>
                                                            <button type='button' className='fp_date_next_btn border'><ArrowForwardIos /></button>
                                                        </div>
                                                    </div>
                                                    <div className='fp_time_main'>
                                                        <ul>
                                                            <li>
                                                                <button type='button' className='fp_time_btn border active'>
                                                                    8:00 AM - 9:30 AM
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button type='button' className='fp_time_btn border'>
                                                                    8:00 AM - 9:30 AM
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button type='button' className='fp_time_btn border'>
                                                                    8:00 AM - 9:30 AM
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button type='button' className='fp_time_btn border'>
                                                                    8:00 AM - 9:30 AM
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='fp_right_footer d-flex align-items-center'>
                                                <Button className="btn btn-primary d-flex align-items-center" onClick={(e) => { continueHandleClick("step3") }} variant="primary">Continue <ArrowForwardIos className='ms-2' /></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }

                    {
                        isNextStep.id === 'step3' ?
                            <div className='fp_modal fp_step3'>
                                <div className="row m-0">
                                    <div className="col-5 p-0">
                                        <div className="mg_modal-left position-relative">
                                            <img src={img} alt="#"></img>
                                            <div className="mg_modal-left-content position-absolute">
                                                <h1>Continue Your Order In-Person</h1>
                                                <di className="fp_modal_left_text">
                                                    <span className='label-1 d-block mb-2 bold text-white'>BMW of Manhattan</span>
                                                    <p>555 W 57th, New York, NY10019</p>
                                                    <p>(212) 586-2269 <Link className='text-white'>www.bwmnyc.com</Link></p>
                                                </di>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-7 p-0">
                                        <div className='fp_right'>
                                            <div className='fp_right_head mb-4'>
                                                <p className='label-1'>Come into your local BMW center to conitnue your application with help from a BMW expert.</p>
                                            </div>
                                            <div className='fp_right_content'>
                                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                                    <div className="content-3--regular-grey">See You There</div>
                                                    <Button className="px-3 py-2" variant="outline-secondary">Edit</Button>
                                                </div>
                                                <div className='fp_step1_box mb-4'>
                                                    <div className='fp_step3_date_main'>
                                                        <div className='fp_step3_date border'>
                                                            <small>DEC</small>
                                                            <b>22</b>
                                                        </div>
                                                        <div className='fp_step3_address'>
                                                            <strong>Wednesday, 8:00 PM</strong>
                                                            <Link><LocationOnIcon />BMW of Manhattan</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='fp_expect_text'>
                                                    <span>WHAT YOU CAN EXPECT DURING VISIT:</span>
                                                    <ul>
                                                        <li>Your checkout progress is synced to the dealer</li>
                                                        <li>Dealer will assist you comoleting the checkout where you left off</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='fp_right_footer d-flex align-items-center'>
                                                <Button className="d-flex align-items-center" variant="primary">Continue <ArrowForwardIos /></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }

                </Modal.Body>
            </Modal>

        </div>
    );
};

export default FinalizeInperson;