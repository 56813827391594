import React from "react";
import Logo from "../Logo";
import styles from './Footer.module.css'
import { Link } from "react-router-dom";
import { FacebookOutlined, Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";

function Footer() {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.footer_row}>
            <div className={styles.footer_col}>
              <Logo />
              <h4>PRIMARY DEALER</h4>
              <ul>
                <li><Link>BMW of Manhattan</Link></li>
                <li><p>+1 212-586-2269</p></li>
                <li ><Link className="text-lowercase">contact@bmwnyc.com</Link></li>
                <li>
                  <p>555 W 57th St, New York 10019</p>
                </li>
                <li>
                  <p>Hours of Operation </p>
                  <p>Mon - Fri: 9AM - 7PM </p>

                  <p>Sat: 10AM - 5PM</p>
                  {/* <p>Sun: 10AM - 5PM</p> */}
                </li>
                <li><Link >www.bmwnyc.<span className="text-lowercase">com</span> </Link></li>
              </ul>
              <div className={styles.social_links}>
                <Link><i className={styles.brands}><FacebookOutlined /></i></Link>
                <Link><i className={styles.brands}><Twitter /></i></Link>
                <Link><i className={styles.brands}><Instagram /></i></Link>
                <Link><i className={styles.brands}><YouTube /></i></Link>
                <Link><i className={styles.brands}><LinkedIn /></i></Link>
              </div>
            </div>

            <div className={styles.footer_col}>
              <h4>CONTACT</h4>
              <ul>
                <li><Link>Contact us</Link></li>
                <li><Link>Find A Dealership</Link></li>

              </ul>
            </div>
            <div className={styles.footer_col}>
              <h4>QUICK LINKS</h4>
              <ul>
                <li><Link>Book a test drive</Link></li>
                <li><Link>Download a brochure</Link></li>

                <li><Link>Book a Service</Link></li>

                {/* <li><Link>Lucid Shop</Link></li> */}

              </ul>
            </div>
            <div className={styles.footer_col}>
              <h4>EXPERIENCE LANE</h4>
              <ul>
                <li><Link>About us</Link></li>

                <li><Link>Careers</Link></li>
              </ul>
            </div>
            <div className={styles.footer_col}>
              <h4>LEGAL</h4>
              <ul>
                <li><Link>Legal Information</Link></li>

                <li><Link>Product Safety Enquiry</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className={styles.footer_bottom}>
        <div className={styles.container}>
          <div className={styles.footerlabel}>
            <div className={styles.footleft}>
              <p>(c) BMW Financial Services 2024</p>
            </div>
            <div className={styles.footright}>
              <ul>
                <li><Link>Legal Notice</Link></li>
                <li><Link>Privacy Policy</Link></li>
                <li><Link>Cookie Policy</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;