import React from "react";
import styles from './BackToResut.module.css'
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

function BackToResut(){
    return(
        <div className={styles.backToResult}>
            <Link to={'/'}>
                <ArrowBack/>
                <div className={styles.backToResult_text}>
                    <h4>BMW of Manhattan</h4>
                    <small>Open until 6 PM</small>
                </div>
            </Link>
        </div>
    )
}

export default BackToResut;