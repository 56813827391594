import { Button, Form } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { Add, CameraAltOutlined, Clear, Logout, Menu, Search } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import frontImg from '../../assets/images/license_front.png';
import backImg from '../../assets/images/license_back.png';

import logo1 from '../../assets/images/lucid-insu1.png';
import logo2 from '../../assets/images/lucid-insu2.jpg';
import logo3 from '../../assets/images/lucid-insu3.png';
import logo4 from '../../assets/images/insuranceProviderLogo4.png';
import logo5 from '../../assets/images/insuranceProviderLogo5.png';
import logo6 from '../../assets/images/insuranceProviderLogo6.png';
import libLogo from '../../assets/images/trellisLogo.png';
import libertylogo from '../../assets/images/libertyLogo.png';
import muLogo1 from '../../assets/images/logo-lucid1.png';
import muTick from '../../assets/images/mi_bmw_tick.png';
import { useLocation } from 'react-router-dom';
import laneLogo from '../../assets/drift_logo.svg';
import avivalog from '../../assets/images/insuranceProviderLogo4.png';
import avivalogmini from '../../assets/images/libertyLogo.png';

import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';

const Insurance = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')
    const link = `${location.pathname}${location.search}`

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);
    const [isDisabledNext, setIsDisabledNext] = useState(true);
    const [show, setShow] = useState(false);


    const [selectedOption, setSelectedOption] = useState('');
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        // console.log(selectedOption)
    };


    const [activeTimeSlot, setTimeSlot] = useState('');

    const [isClassAdded, setIsClassAdded] = useState(false);
    const toggleClass = () => {
        setIsClassAdded(!isClassAdded);
    };


    return (
        <div>
            <div className="digital_checkout_wrapper">

                <div className="d-flex">
                    <div className={`checkoutSideNavMain ${isClassAdded ? 'open' : ''}`}>
                        <CheckoutSideNav />
                        <Button className="dcoCloseBtn" variant="outline-secondary" onClick={toggleClass}><Clear className="m-0" /></Button>
                    </div>
                    <div className="checkout_rightContent pb-0">
                        <div className="po_header pb-3">
                            <div className="po_header_inner">
                                <Button className="dcoMenuBtn" variant="outline-secondary px-2" onClick={toggleClass}><Menu className="m-0" /></Button>
                                <div className='d-flex justify-content-end align-items-center'>

                                    <Link to={'/DigitalCheckout'}>
                                        <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='iad_content po_content_height'>
                            <div className='d-flex justify-content-between'>
                                <div className='po_headlines'>
                                    <h3 className='headline-5 regular pb-1 d-flex align-items-center uppercase'>Insurance & Registration</h3>
                                    <p className='label-2 regular pb-3'>Provide your current insurance information or shop for a new provider.</p>
                                </div>
                            </div>



                            <div className='dap_content'>
                                <div className="rd_section mb-5">
                                    <div className='row'>
                                        <div className='col-lg-9 d-flex align-items-center pb-3 justify-content-between'>
                                            <h3 className='headline-6 light'><span className="text-secondary pe-3">01</span>Upload Your Identity Document</h3>

                                        </div>
                                        <div className="col-lg-9">
                                            <div className='bd_box border mb-4'>
                                                {/* uploaded images sec */}
                                                <div className='ins_uploadLicense_row d-flex align-items-center pb-4'>
                                                    <div className='col'>
                                                        <div className='ins_licenseImgBox border'>
                                                            <img src={"https://unity-generated-documents.s3.amazonaws.com/BiSqNIkCSZ.png"} alt='#'></img>
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className='ins_licenseImgBox border'>
                                                            <img src={"https://unity-generated-documents.s3.amazonaws.com/NGISYUDYDX.png"} alt='#'></img>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-end justify-content-start'>
                                                    <Button disabled={activeTimeSlot === '' ? true : false} variant='dark'>Save & Continue</Button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* sec2 */}

                                    <div className='row'>
                                        <div className='col-lg-9 d-flex align-items-center pb-3 justify-content-between'>
                                            <h3 className='headline-6 light'><span className="text-secondary pe-3">02</span>Enter Your Garaging Address</h3>

                                        </div>
                                        <div className=' col-lg-9'>
                                            <div className='bd_box border mb-4'>
                                                <p className='content-3--regular-grey mb-3'>Select the option that applies to your purchase.</p>

                                                <div className='bd_your_lease_sec d-flex align-items-center pb-4'>
                                                    <div className={`bd_box border dap_radio_box margin-right-3  active`}>
                                                        <div className='pe-0 border-bottom pb-3 mb-3'>
                                                            <label className='d-flex align-items-center content-3 bold black'>
                                                                <Form.Check type="radio" name='Delivery' aria-label="radio 1" value="option1"
                                                                    checked={true}
                                                                    onChange={handleOptionChange} />

                                                                <span className='ps-2'>Current Address</span>

                                                            </label>
                                                        </div>
                                                        <p className='content-3--regular-grey ps-2 pe-2'>822 Sarbonne Rd,</p>
                                                        {/* <p className='content-3--regular-grey ps-2 pe-2'>Suite #PH</p> */}
                                                        <p className='content-3--regular-grey ps-2 pe-2'> Los Angeles, California 90077 </p>

                                                    </div>

                                                    <div className={`bd_box border dap_radio_box ${selectedOption === 'option2' ? 'active' : ''}`}>
                                                        <div className='pe-0 border-bottom pb-3 mb-3'>
                                                            <label className='d-flex align-items-center content-3 bold black'>
                                                                <Form.Check type="radio" name='Delivery' aria-label="radio 1" value="option2"
                                                                    checked={selectedOption === 'option2'}
                                                                    onChange={handleOptionChange} />
                                                                <span className='ps-2'>Permanent Address</span>

                                                            </label>
                                                        </div>
                                                        <p className='content-3--regular-grey ps-2 pe-2'>822 Sarbonne Rd,</p>
                                                        {/* <p className='content-3--regular-grey ps-2 pe-2'>Suite #PH</p> */}
                                                        <p className='content-3--regular-grey ps-2 pe-2'> Los Angeles, California 90077 </p>

                                                    </div>
                                                </div>
                                                <Link to={link} className='d-flex flex-wrap justify-content-between align-items-center mb-4 label-1'>
                                                    <span className=' bold'>Enter a Different Address</span>
                                                    <Add />
                                                </Link>

                                                <div className='d-flex align-items-end justify-content-start'>
                                                    <Button disabled={activeTimeSlot === '' ? true : false} variant='dark'>Save & Continue</Button>
                                                </div>
                                            </div>


                                            {/* sec 2 result */}

                                            <div className='bd_box border mb-4'>
                                                <p className='label-2 bold pb-1'>Garaging Address</p>
                                                <p className='label-1 mb-3'>822 Sarbonne Rd,<br></br>  Los Angeles, California 90077 </p>
                                            </div>

                                        </div>

                                    </div>


                                    {/* sec3 */}

                                    <div className='row'>
                                        <div className='col-lg-9 d-flex align-items-center pb-3 justify-content-between'>
                                            <h3 className='headline-6 light'><span className="text-secondary pe-3">03</span>Provide Your Insurance Information</h3>

                                        </div>
                                        <div className=' col-lg-9'>
                                            <div className='bd_box border mb-4'>
                                                <p className='content-3--regular-grey mb-3'>Do you currently have insurance?</p>

                                                <div className='bd_your_lease_sec d-flex align-items-center pb-4'>
                                                    <div className={`bd_box border dap_radio_box margin-right-3 ${selectedOption === 'hasInsurance' ? 'active' : ''}`}>
                                                        <div className='pe-0'>
                                                            <label className='d-flex align-items-center content-3 bold black'>
                                                                <Form.Check type="radio" name='insurance' aria-label="radio 1" value="hasInsurance"
                                                                    checked={selectedOption === 'hasInsurance'}
                                                                    onChange={handleOptionChange} />
                                                                <span className='ps-2'>Yes, I have an existing  insurance policy</span>

                                                            </label>
                                                        </div>


                                                    </div>

                                                    <div className={`bd_box border dap_radio_box ${selectedOption === 'newInsurance' ? 'active' : ''}`}>
                                                        <div className='pe-0'>
                                                            <label className='d-flex align-items-center content-3 bold black'>
                                                                <Form.Check type="radio" name='insurance' aria-label="radio 1" value="newInsurance"
                                                                    checked={selectedOption === 'newInsurance'}
                                                                    onChange={handleOptionChange} />
                                                                <span className='ps-2'>No, I would like to shop for insurance</span>
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='existingIns_showbox'>
                                                    <div className="search_input_container mb-2">
                                                        <div className="search_vin_icon"><Search /></div>
                                                        <Form.Control type="text" placeholder="Search For Your Insurance Provider" />
                                                    </div>
                                                </div>

                                                <div className='extInsurance_logos_row d-flex flex-wrap align-items-center mb-4'>
                                                    <div className='extInsurance_logoBox col-md-4'>
                                                        <Link to={link} onClick={() => setShow(true)} className='bd_box border p-2'>
                                                            <img src={avivalog} alt='#'></img>
                                                        </Link>
                                                    </div>
                                                    <div className='extInsurance_logoBox col-md-4'>
                                                        <Link to={link} className='bd_box border p-2'>
                                                            <img src={logo5} alt='#'></img>
                                                        </Link>
                                                    </div>
                                                    <div className='extInsurance_logoBox col-md-4'>
                                                        <Link to={link} className='bd_box border p-2'>
                                                            <img src={logo6} alt='#'></img>
                                                        </Link>
                                                    </div>
                                                </div>

                                                {/* Liberty Mutual Insurance title header */}
                                                {show ? <div className='lib_profileInfo d-flex align-items-center mb-3'>
                                                    {/* <div className='lib_profileAvatar'>
                                                        <span> <img className='d-block' src={libLogoAvatar} alt='#'></img></span>
                                                    </div> */}
                                                    <div className='lib_profileHeading ps-3'>
                                                        <h3 className='label-1 bold'>Liberty Mutual</h3>
                                                        <p className='label-2'>https://www.libertymutual.com/log-in</p>
                                                    </div>

                                                </div> : null}

                                                {/* insurance login form */}

                                                {show ? <div className='insu_loginForm'>
                                                    <p className='label-2 pb-3'>Enter your credentials:</p>

                                                    <div className='row'>
                                                        <div className='col-12 mb-3'>
                                                            <Form.Control type="text" placeholder="Username" />
                                                        </div>
                                                        <div className='col-12 mb-3'>
                                                            <Form.Control type="password" placeholder="Password" />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-end justify-content-start'>
                                                        <Button variant='dark' onClick={() => setIsDisabledNext(false)}>Log In</Button>
                                                    </div>
                                                    <p className='pt-2'>
                                                        <Link to={link} className='underline_text'>Fill Out Forms Manually</Link>
                                                    </p>
                                                </div> : null}

                                                {/* insurance after login logos section */}

                                                {!isDisabledNext ? <div className='insu_mu_logosSec pt-4 pb-2'>


                                                    <div className='insu_mu_logos'>
                                                        <div className='insu_mu_bmw'>
                                                            <img src={laneLogo}></img>
                                                        </div>
                                                        <div className='insu_mu_mid'>
                                                            <i><img src={muTick}></img></i>
                                                            <p>Your Insurance Information is Verified & Transferred</p>
                                                        </div>
                                                        <div className='insu_mu_co'>
                                                            <img src={avivalogmini}></img>
                                                        </div>
                                                    </div>


                                                    <div className='d-flex align-items-end justify-content-start pt-4'>
                                                        <Link to={`/ReviewAndSign?orderId=${orderID}&customerRefId=${customerRefId}`}>
                                                            <Button variant='dark'>Continue to Delivery</Button>
                                                        </Link>
                                                    </div>

                                                </div> : null}
                                            </div>


                                        </div>

                                    </div>



                                    <div className="rd_section mb-5">
                                        <div className='row'>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <DigitalCheckoutFooter pageUrl={`/ReviewAndSign?orderId=${orderID}&customerRefId=${customerRefId}`} disabled={isDisabledNext} />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default Insurance;