import React, { useState } from 'react';
import logo from '../../assets/images/logo.svg';
import { Link } from "react-router-dom";
import endpagecarimg from '../../assets/images/endingpagecar2.png';
import endpagecarimg2 from '../../assets/images/epcar3.png';
import endpagecarimg3back from '../../assets/images/endingpagecar3back.png';
import endpagecarimg3 from '../../assets/images/endingpagecar1.png';
import endpagevideoimg1 from '../../assets/images/endpagevideo1.jpg';
import endpagevideoimg2 from '../../assets/images/endpagevideo2.jpg';
import { LogoutOutlined, NightlightRoundOutlined, WbSunnyOutlined } from '@mui/icons-material';
import EndingPageSlider from '../../ScreensUi/DigitalCheckout/EndingPageSlider';
import PlusToggleBtn from '../../ScreensUi/DigitalCheckout/PlusToggleBtn';
import acc1 from '../../assets/images/accimg1.png';
import acc2 from '../../assets/images/accimg2.png';
import acc3 from '../../assets/images/accimg3.png';
import acc4 from '../../assets/images/accimg4.png';
import acc5 from '../../assets/images/acc5.png';
import acc6 from '../../assets/images/acc6.png';
import acc7 from '../../assets/images/acc7.png';
import acc8 from '../../assets/images/acc8.png';
import acc9 from '../../assets/images/acc9.png';
import blacktheme1 from '../../assets/images/btheme1.png';
import blacktheme2 from '../../assets/images/btheme2.png';
import blacktheme3 from '../../assets/images/btheme3.png';
import blacktheme4 from '../../assets/images/btheme4.png';

function EndingPage() {
    const [isDarkMode, setDarkMode] = useState(false);

    // Toggle dark mode
    const toggleDarkMode = () => {
        setDarkMode(!isDarkMode);
    };

    return (
        <div className='mg_ep-wrapper'>
            <div className={isDarkMode ? 'mg_ep-wrapper-dark' : ''}>

                <div className="header mg_endingpage-header">
                    <div className="header_inner d-flex justify-content-between align-items-center">
                        <div className='d-flex'>
                            <div className="header_logo">
                                <Link to="/MainHomePage">
                                    <img src={logo} alt="logo"></img>
                                </Link>
                            </div>
                            <Link className="d-flex align-items-center header_location px-3">
                                BMW of Manhattan
                            </Link>
                        </div>
                        <Link to='/' className="d-flex align-items-center header_location filtersH_link mg_ending-btn border">
                            <LogoutOutlined />
                            Sign Out
                        </Link>
                    </div>
                </div>
                <div className='mg_endingpage-ctr'>
                    <div className=''>
                        <div className='col-12 row py-3 mg_flex-reverse'>
                            <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center'>
                                <div className='mg_endingpageleft-content'>
                                    <h3>Devin, We're Getting Your BMW Ready</h3>
                                    <p>Your order is condirmed and your BMW 330ixDrive is being prepared for delivery. </p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='mg_endingpageright-content d-flex justify-content-end'>
                                    <img className='mg_img mg_img-light' src={endpagecarimg} alt='#'></img>
                                    <img className='mg_img mg_img-dark' src={blacktheme2} alt='#'></img>
                                </div>
                            </div>
                        </div>
                        <div className='mb-4 mg_ep-themebtn mg_auto-content'>
                            <ul className='d-flex justify-content-end'>

                                <li className='p-2'><Link className='filtersH_link btn btn-outline-secondary mg_btn p-3' onClick={toggleDarkMode}><WbSunnyOutlined /></Link></li>
                                <li className='p-2'><Link className='filtersH_link btn btn-outline-secondary mg_btn p-3' onClick={toggleDarkMode}><NightlightRoundOutlined /></Link></li>
                            </ul>
                        </div>
                        <div className='mg_auto-content p-4'>
                            <div className='col-12 py-2 label-2 bold mg_epdarktheme-txt'>While You Wait</div>
                            <div className='mg_whileuwait mb-4'>
                                <div className='row p-2'>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <div className='d-flex justify-content-between align-items-center p-4 mg_whileuwait-content'>
                                            <ul className='d-flex flex-column'>
                                                <li><span className='content-2--regular-grey'>Nov. 12, 2022</span></li>
                                                <li><p className='pe-2 small'>Estimated delivery Date</p></li>
                                            </ul>
                                            <button className='product_list_btn ps-3 pe-3 btn btn-primary'>Contact Us</button>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <div className='d-flex justify-content-between align-items-center p-4 mg_whileuwait-content'>
                                            <ul className='d-flex flex-column'>
                                                <li><span className='content-2--regular-grey'>Manage MY Order</span></li>
                                                <li><p className='pe-2 small'>Order # 65487809785</p></li>
                                            </ul>
                                            <button className='product_list_btn ps-3 pe-3 btn btn-secondary'>Edit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-12 mg_endingtabscontent'>
                    <ul className='d-flex justify-content-center'>
                        <li><Link className="header_location mg_ending-tab">Highlights</Link></li>
                        <li><Link className="header_location mg_ending-tab">Details</Link></li>
                        <li><Link className="header_location mg_ending-tab">Accesories</Link></li>
                    </ul>

                </div>
                <div className='col-12'>
                    <div className='col-12 row'>
                        <div className='col-lg-6 col-md-6 col-12 p-4 d-flex justify-content-center align-items-center'>
                            <div className='mg_endingpageleft-content'>
                                <span className='py-2 label-2 bold'>HIGHLIGHTS</span>
                                <h3>STRIKING SEDAN STYLE</h3>
                                <p>Experience the soul of an iconic sports sedan.Bold exterior design,clossy interior, and personalized technology define the 2023 BMW 3 Series Sedan.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12 p-0'>
                            <div className='mg_endingpageright-content d-flex justify-content-end'>
                                <img className='mg_img mg_img-light' src={endpagecarimg3back} alt='#'></img>
                                <img className='mg_img mg_img-dark' src={blacktheme1} alt='#'></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='mg_endingpageright-content d-flex justify-content-center'>
                        <div className='mg_highlight-centerimg'>
                            <img className='mg_img mg_img-light' src={endpagecarimg2} alt='#'></img>
                            <img className='mg_img mg_img-dark' src={blacktheme3} alt='#'></img>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='row mg_flex-reverse'>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='mg_endingpageright-content d-flex justify-content-start'>
                                <div className=''>
                                    <img className='mg_img mg_img-light' src={endpagecarimg3} alt='#'></img>
                                    <img className='mg_img mg_img-dark' src={blacktheme4} alt='#'></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12 p-4 d-flex flex-column mb-3 justify-content-center align-items-center'>
                            <div className='mg_endingpageleft-content mg_ep-content'>
                                <h3>THE 3 PUSHES FORWARD</h3>
                                <p>The sport sedan that started it call continue its exciting evalution with bold styling, modern finishes, and inclusive technology.</p>
                            </div>
                            <div className='d-flex justify-content-start py-2'>
                                <Link className='d-flex flex-column p-2 header_location'>
                                    <img className='py-2 mg_img' src={endpagevideoimg1} alt='#'></img>
                                    <span className='label-2 bold'>A luxurious Interior</span>
                                    <p className='pe-2 small'>The cabin of the BMW 330i 24s</p>
                                </Link>
                                <Link className='d-flex flex-column p-2 header_location'>
                                    <img className='py-2 mg_img' src={endpagevideoimg2} alt='#'></img>
                                    <span className='label-2 bold'>M Sport Packages</span>
                                    <p className='pe-2 small'>style with serious pedigree 33s</p>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 mg_endingpage-ctr mg_dist-details'>
                    <div className='mg_auto-content px-4'>
                        <h3 className='headline-5 regular'>DISTINGUISHED DETAILS</h3>
                        <p className='mb-3'>The BMW 330i Sedana invite you into an engaging enviornment of distinct features,seamless technology, and modern design.</p>

                    </div>
                    <div className='py-4 mg_sliderposition'>
                        <div className="rd_slider">
                            <EndingPageSlider />
                        </div>
                    </div>
                </div>
                <div className='col-12 p-4 mg_sride-ctr'>
                    <div className='mg_auto-content'>
                        <div className='col-12'>
                            <span>RECOMMENDED FOR YOU</span>
                            <h3 className='headline-5 regular'>SPRUCE UP YOUR RIDE</h3>
                            <p>Make it your own BMW orignal accessories.</p>
                            <ul className='d-flex justify-content-start py-2'>
                                <li className='p-1'><Link className="btn btn-outline-secondary mg_btn">All Accesories</Link></li>
                                <li className='p-1'><Link className="btn btn-outline-secondary mg_btn">Exterior</Link></li>
                                <li className='p-1'><Link className="btn btn-outline-secondary mg_btn">Interior</Link></li>
                                <li className='p-1'><Link className="btn btn-outline-secondary mg_btn">Charging</Link></li>
                                <li className='p-1'><Link className="btn btn-outline-secondary mg_btn">Technology</Link></li>
                                <li className='p-1'><Link className="btn btn-outline-secondary mg_btn">Approval</Link></li>
                            </ul>
                        </div>
                        <div className='col-12'>
                            <div className='iad_cards_list'>
                                <div className='row pb-4 pt-4'>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link className='iad_cards_img_link'>
                                                    <img src={acc1} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                <strong className='label-2 bold'>-$750</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link className='iad_cards_img_link'>
                                                    <img src={acc2} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                <strong className='label-2 bold'>-$750</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link className='iad_cards_img_link'>
                                                    <img src={acc3} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                <strong className='label-2 bold'>-$750</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link className='iad_cards_img_link'>
                                                    <img src={acc4} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                <strong className='label-2 bold'>-$750</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 py-4'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-12 pb-3'>
                                                <div className='iad_cards_box w-100'>
                                                    <div className='iad_cards_img mg_col6height'>
                                                        <Link className='iad_cards_img_link'>
                                                            <img src={acc5} alt='#'></img>
                                                        </Link>
                                                        <PlusToggleBtn />
                                                    </div>
                                                    <div className='iad_cards_info'>
                                                        <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                        <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                        <strong className='label-2 bold'>-$750</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12 pb-3'>
                                                <div className='iad_cards_box w-100'>
                                                    <div className='iad_cards_img mg_col6height'>
                                                        <Link className='iad_cards_img_link'>
                                                            <img src={acc6} alt='#'></img>
                                                        </Link>
                                                        <PlusToggleBtn />
                                                    </div>
                                                    <div className='iad_cards_info'>
                                                        <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                        <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                        <strong className='label-2 bold'>-$750</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-3 col-12'>
                                                <div className='d-flex flex-column'>
                                                    <div className='py-2'>
                                                        <div className='iad_cards_box w-100'>
                                                            <div className='iad_cards_img mg_col6height'>
                                                                <Link className='iad_cards_img_link h-100'>
                                                                    <img src={acc8} alt='#'></img>
                                                                </Link>
                                                                <PlusToggleBtn />
                                                            </div>
                                                            <div className='iad_cards_info'>
                                                                <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                                <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                                <strong className='label-2 bold'>-$750</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='py-2' >
                                                        <div className='iad_cards_box w-100'>
                                                            <div className='iad_cards_img mg_col6height'>
                                                                <Link className='iad_cards_img_link h-100'>
                                                                    <img src={acc9} alt='#'></img>
                                                                </Link>
                                                                <PlusToggleBtn />
                                                            </div>
                                                            <div className='iad_cards_info'>
                                                                <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                                <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                                <strong className='label-2 bold'>-$750</strong>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-9 col-md-9 col-12'>
                                                <div className='iad_cards_box w-100'>
                                                    <div className='iad_cards_img'>
                                                        <Link className='iad_cards_img_link mg_height'>
                                                            <img src={acc7} alt='#'></img>
                                                        </Link>
                                                        <PlusToggleBtn />
                                                    </div>
                                                    <div className='iad_cards_info'>
                                                        <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                        <p className='label-2 pb-1'>Applies to lease/finance contracts for 2021-2023 BMW models</p>
                                                        <strong className='label-2 bold'>-$750</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </div>
        </div>

    );
}

export default EndingPage;