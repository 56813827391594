import { Modal, OverlayTrigger } from 'react-bootstrap';
import React, { useContext, useEffect, useReducer } from 'react';
import { Price } from '../../../utilities'

import Tooltip from '@mui/material/Tooltip';
import { Add, InfoOutlined } from '@mui/icons-material';
import { context } from './PricingDetail';

const initialState = {
  RESIDUAL_PERCENTAGE_LEASE: 0,
  RESIDUAL_VALUE_LEASE: 0,

  RESIDUAL_PERCENTAGE_FINANCE: 0,
  RESIDUAL_VALUE_FINANCE: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RESIDUAL_VALUE_LEASE':
      return {
        ...state,
        RESIDUAL_PERCENTAGE_LEASE: action.payload.RESIDUAL_PERCENTAGE_LEASE,
        RESIDUAL_VALUE_LEASE: action.payload.RESIDUAL_VALUE_LEASE
      }

    case 'SET_RESIDUAL_VALUE_FINANCE':
      return {
        ...state,
        RESIDUAL_PERCENTAGE_FINANCE: action.payload.RESIDUAL_PERCENTAGE_FINANCE,
        RESIDUAL_VALUE_FINANCE: action.payload.RESIDUAL_VALUE_FINANCE
      }

    default:
      return state
  }
}

const PricingDetailModal = ({ showModal, handleCloseModal, ASSET, PROGRAMS }) => {
  const { state, dispatch } = useContext(context)
  const [stateLocal, dispatchLocal] = useReducer(reducer, initialState)

  useEffect(() => {
    PROGRAMS.forEach(e => {
      if (e.finance_type !== "Finance") {
        dispatchLocal({
          type: 'SET_RESIDUAL_VALUE_LEASE',
          payload: {
            RESIDUAL_PERCENTAGE_LEASE: e.rv_chart ? e.rv_chart[0].rv_value : 0,
            RESIDUAL_VALUE_LEASE: e.rv_chart ? (ASSET.internet_price * e.rv_chart[0].rv_value / 100) : 0
          }
        })
      } else {
        dispatchLocal({
          type: 'SET_RESIDUAL_VALUE_FINANCE',
          payload: {
            RESIDUAL_PERCENTAGE_FINANCE: e.rv_chart ? e.rv_chart[0].rv_value : 0,
            RESIDUAL_VALUE_FINANCE: e.rv_chart ? (ASSET.internet_price * e.rv_chart[0].rv_value / 100) : 0
          }
        })
      }
    })
  }, [PROGRAMS])

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" className='pricingDetalModalMain'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='pd_modal'>
            <div className='pd_modal_title mb-3'>
              <span className='label-2 regular d-block'>{ASSET.year} {ASSET.make} </span>
              <span className='label-1 regular d-block'>{ASSET.model} {ASSET.trim_description} {ASSET.body_type}</span>
            </div>
            <div className='pd_detail mb-3 d-flex flex-wrap'>
              <figure>
                <img src={ASSET.photo_urls[0].location} alt='#' />
              </figure>
              <div className='pd_detail_right d-flex flex-wrap'>
                <div className='pd_detail_text border-end'>
                  <span className='label-2 regular d-block mb-1'>{ASSET.engine_description}</span>
                  <span className='label-2 regular d-block mb-1'>{ASSET.drive_train}</span>
                  <span className='label-2 regular d-block mb-1'>{ASSET.trim_description}</span>
                </div>
                <div className='pd_detail_text'>
                  <ul>
                    <li className='label-2 regular d-flex align-items-center mb-1'>
                      {ASSET.exterior_color_description}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='pd_info'>
              <div className='bd_breakdown px-2'>
                <div className='d-flex justify-content-center py-2 border-top border-bottom'>
                  <span className='label-2 regular pe-3'>Model {ASSET.model}</span>
                  <span className='label-2 regular'>VIN {ASSET.vin}</span>
                </div>
                <div className='row pt-3'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title mb-0 me-2">Base Model</span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title  mb-0"><Price value={ASSET.internet_price} /></span>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title mb-0 me-2">Packages and Options</span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title  mb-0"><Price value={0} /></span>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title d-flex align-items-center mb-0 me-2">Manufacturer Destination Charge
                      <span className='msrp_tip'>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip className='tooltip_box pd_tooltip  tooltip_box pd_tooltip _top'>
                              <>A non-negotiable fee added to the price of a new car to cover the cost of shipping the vehicle from the manufacturer to the dealership.</>
                            </Tooltip>
                          }
                        >
                          <i className='help_icon help_icon_white ms-1'><InfoOutlined /></i>
                        </OverlayTrigger>
                      </span>
                    </span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title mb-0"><Price value={0} /></span>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title d-flex align-items-center mb-0 me-2">MSRP (Sticker Price)
                      <span className='msrp_tip'>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip className='tooltip_box pd_tooltip  tooltip_box pd_tooltip _top'>
                            <>The manufacturer's suggested retail price.</>
                          </Tooltip>}
                        >
                          <i className='help_icon help_icon_white ms-1'><InfoOutlined /></i>
                        </OverlayTrigger>
                      </span>
                    </span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title mb-0"><Price value={ASSET.internet_price} /></span>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title mb-0 me-2 d-flex align-items-center">BMW of Manhattan Price
                    </span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title  mb-0"><Price value={ASSET.internet_price} /></span>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title mb-0 me-2 d-flex align-items-center">Total Purchase Price
                    </span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title  mb-0"><Price value={ASSET.internet_price} /></span>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-md-12'>
                    <div className='d-flex justify-content-center pd_info_row_bg py-2 border-top border-bottom'>
                      <span className='label-2 regular pe-3'>SUMMARY</span>
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title mb-0 me-2 d-flex align-items-center">Term
                    </span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title  mb-0">{state.SELECTED_CONTRACT_TERM} months</span>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title d-flex align-items-center mb-0 me-2">Annual Mileage
                      <span className='msrp_tip'>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip className='tooltip_box pd_tooltip  tooltip_box pd_tooltip _top'>
                            <>The maximum number of Miles a lease vehicle can be driven per year without incurring a penalty. You can usually pay for additional Miles at the start of a lease for significantly less than the penalty you would incur for the same Miles at the end of the lease.</>
                          </Tooltip>}
                        >
                          <i className='help_icon help_icon_white ms-1'><InfoOutlined /></i>
                        </OverlayTrigger>
                      </span>
                    </span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title mb-0">{state.MILEAGE}</span>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title d-flex align-items-center mb-0 me-2">Residual Value ( {
                      state.PROGRAM === 'Lease' ?
                        stateLocal.RESIDUAL_PERCENTAGE_LEASE :
                        stateLocal.RESIDUAL_PERCENTAGE_FINANCE} % )
                      <span className='msrp_tip'>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip className='tooltip_box pd_tooltip  tooltip_box pd_tooltip _top'>
                            <>The predicted wholesale value of your car at the end of the lease. Residual Value is often expressed as a percentage of the vehicle’s MSRP and is always non-negotiable.</>
                          </Tooltip>}
                        >
                          <i className='help_icon help_icon_white ms-1'><InfoOutlined /></i>
                        </OverlayTrigger>
                      </span>
                    </span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title mb-0">{state.PROGRAM === 'Lease' ? <Price value={stateLocal.RESIDUAL_VALUE_LEASE} /> : <Price value={stateLocal.RESIDUAL_VALUE_FINANCE} />}</span>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-3'>
                    <span className="acc_title mb-0 me-2">Total Due At Signing</span>
                  </div>
                  <div className='col-4 pb-3 text-end'>
                    <span className="acc_title  mb-0">{state.PROGRAM === 'Finance' && state.RENTAL_MODE =='Arrear' ? <Price value={state.FINANCE_DOWN_PAYMENT_AMOUNT} /> : state.PROGRAM === 'Finance' && state.RENTAL_MODE =='Advance' ? <Price value={state.ESTIMATED_PRICE_FINANCE + state.FINANCE_DOWN_PAYMENT_AMOUNT} />: <Price value={state.ESTIMATED_PRICE_FINANCE + state.LEASE_DOWN_PAYMENT_AMOUNT} />}</span>
                  </div>
                  <div className='col-12 pd_info_sublist'>
                    <div className='row'>
                      <div className='col-8 pb-2'>
                        <span className="acc_title d-flex align-items-center mb-0 me-2"><Add className="me-1 plus_icon" />First Payment
                        </span>
                      </div>
                      <div className='col-4 pb-2 text-end'>
                        <span className="acc_title mb-0">{state.PROGRAM === 'Lease' ? <Price value={state.ESTIMATED_PRICE_LEASE} /> : <Price value={state.ESTIMATED_PRICE_FINANCE} />}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 pd_info_sublist'>
                    <div className='row'>
                      <div className='col-8 pb-2'>
                        <span className="acc_title d-flex align-items-center mb-0 me-2"><Add className="me-1 plus_icon" />Cash Down
                        </span>
                      </div>
                      <div className='col-4 pb-2 text-end'>
                        <span className="acc_title mb-0">{state.PROGRAM === 'Lease' ? <Price value={state.LEASE_DOWN_PAYMENT_AMOUNT} /> : <Price value={state.FINANCE_DOWN_PAYMENT_AMOUNT} />}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 pd_info_sublist'>
                    <div className='row'>
                      <div className='col-8 pb-2'>
                        <span className="acc_title d-flex align-items-center mb-0 me-2"><Add className="me-1 plus_icon" />Sales Tax
                        </span>
                      </div>
                      <div className='col-4 pb-2 text-end'>
                        <span className="acc_title mb-0"><Price value={0} /></span></div>
                    </div>
                  </div>
                </div>
                <div className="filter_options_divider mt-2 mb-3"></div>
                <div className='row'>
                  <div className='col-8 pb-2'>
                    <span className="acc_title d-flex align-items-center mb-0 me-2">Monthly Payment
                    </span>
                  </div>
                  <div className='col-4 pb-2 text-end'>
                    <span className="acc_title mb-0">{state.PROGRAM === 'Lease' ? <Price value={state.ESTIMATED_PRICE_LEASE} /> : <Price value={state.ESTIMATED_PRICE_FINANCE} />}</span>
                  </div>
                </div>
                <div className="filter_options_divider mt-4 mb-3"></div>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

    </div>
  );
};

export default PricingDetailModal;