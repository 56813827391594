import { Button, OverlayTrigger } from "react-bootstrap";
import { ChatBubbleOutlineOutlined, Clear, Logout, Menu, SwapHoriz, Verified } from "@mui/icons-material";
import { useLocation } from 'react-router-dom';
import CheckoutSideNav from "../../ScreensUi/CheckoutSideNav/CheckoutSideNav";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import styles from "../../ScreensUi/ProductDetails/PricingDetail/PricingDetail.module.css";


function DigitalCheckout() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')

    const [isClassAdded, setIsClassAdded] = useState(false);
    const toggleClass = () => {
        setIsClassAdded(!isClassAdded);
    };

    return (
        <div className="digital_checkout_wrapper">

            <div className="d-flex">
                <div className={`checkoutSideNavMain ${isClassAdded ? 'open' : ''}`}>
                    <CheckoutSideNav />
                    <Button className="dcoCloseBtn" variant="outline-secondary" onClick={toggleClass}><Clear className="m-0" /></Button>
                </div>
                <div className="checkout_rightContent has_image">

                    <div className="leave_button">
                        <div className="d-flex justify-content-end">

                            <Button className="dcoMenuBtn" variant="outline-secondary px-2" onClick={toggleClass}><Menu className="m-0" /></Button>
                            <Link to={'/ProductDetails'}>
                                <Button variant="outline-secondary"><Logout />Leave</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="checkout_rightContent_inner">
                        <div className="row main_content_sec">

                            <div className="col-lg-5">

                                <br />

                                <div className="leftSection" >
                                    <h2>2024 BMW X7 M60i xDrive</h2>
                                    <h1>DIGITAL <br /> CHECKOUT</h1>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="rightSection">

                                    <br />
                                    <div className="d-flex justify-content-start">
                                        <div className="d-flex align-items-center">
                                            <Link className="color_white" >
                                                <Verified />
                                            </Link>

                                            <p className="digital_text">Build your perfect deal with all figures and estimates provided by BMW of Manhattan</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <div className="d-flex align-items-center">
                                            <Link className="color_white d-flex align-items-center">
                                                <i className="has_status_icon me-1">
                                                    <small className="status_tag"></small>
                                                    <ChatBubbleOutlineOutlined className="d-block me-1" />
                                                </i>
                                            </Link>
                                            <p className="digital_text">Connect with a live sales agent at any point to answer your questions and aide in building your deal</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <div className="d-flex align-items-center">
                                            <Link className="color_white" >
                                                <SwapHoriz />
                                            </Link>
                                            <p className="digital_text">Complete the entire purchase online or finish in-person at BMW of Manhattan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer_pricing_sec">

                            <div className="footer_pricing_auto d-flex justify-content-between align-items-center">
                                <div className="fp_pagi">

                                </div>
                                <div className="d-flex align-items-center">
                                    <div className={styles.price_heading_box}>
                                        <small className="d-flex align-items-center eyebrow-2">Estimated Payment <span className='msrp_tip'>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip className='tooltip_box tooltip_box_top'>Manufacturer's Suggested Retail Price (MSRP) for vehicle as shown does not necessarily represent the dealer's actual sale price and does not include tax, title, license, registration and adjusted market value. Dealer sets actual price. Please consult your selected dealer.</Tooltip>}
                                            >
                                                <i className='help_icon help_icon_white ms-1'><HelpOutlineIcon /></i>
                                            </OverlayTrigger>
                                        </span></small>
                                        <h2 className="headline-5 bold">$ 2,496.89<small>/month</small></h2>
                                    </div>

                                    <div className="footer_pricing_btn">
                                        <Link to={`/PaymentOptions?orderId=${orderID}&customerRefId=${customerRefId}`} className='d-block mb-1'>
                                            <div href="" className="btn">Build My Deal &gt;</div>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalCheckout