import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query'
import {UpscopeProvider} from '@upscopeio/react';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

// generate a unique guid
const guid = () =>{
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <QueryClientProvider client={new QueryClient()}>
        <BrowserRouter>
          <UpscopeProvider apiKey="TpXkgK2h1b" enabled={true} uniqueId={guid()}>
            <App/>
          </UpscopeProvider>
        </BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-left'/> */}
      </QueryClientProvider>
    </React.StrictMode>
);
