import './App.css';
import './Styles/style.css'
import './Styles/style1.css'
import './Styles/style2.css'
import './Styles/style3.css'
import './Styles/style4.css'
import './Styles/responsive.css'

import PagesRoutes from './Routes/PagesRoutes';
import { useEffect } from 'react';
import { loadJsFile } from './utilities/methods'

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify({"email":"dealermanhattan@yopmail.com","product_id":14,"role":{"name":"Index Dealer Admin User","is_admin":null},"user_name":"John Doe","tenant_id":5510,"company_name":"BMW Financial Services","is_logged_in":true,"refresh_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3NjkyLCJlbWFpbCI6ImRlYWxlcm1hbmhhdHRhbkB5b3BtYWlsLmNvbSIsInRlbmFudF9pZCI6NTUxMCwidXNlcl9yb2xlcyI6W3sicm9sZV9pZCI6Mjg1LCJuYW1lIjoiRGVhbGVyIEFkbWluIiwicHJvZHVjdF9pZCI6MTQsImlzX2FkbWluIjpudWxsLCJleHRlcm5hbF9jb2RlIjpudWxsfSx7InJvbGVfaWQiOjI4NiwibmFtZSI6IkluZGV4IERlYWxlciBBZG1pbiBVc2VyIiwicHJvZHVjdF9pZCI6MTEsImlzX2FkbWluIjpudWxsLCJleHRlcm5hbF9jb2RlIjpudWxsfV0sImV4cCI6MTc0OTQ5MTQ0Nn0.e7UC6IGJE5NVkYeSLo6qgIyC4ct_J1T6rcYKLk2mB_k","access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3NjkyLCJlbWFpbCI6ImRlYWxlcm1hbmhhdHRhbkB5b3BtYWlsLmNvbSIsInRlbmFudF9pZCI6NTUxMCwidXNlcl9yb2xlcyI6W3sicm9sZV9pZCI6Mjg1LCJuYW1lIjoiRGVhbGVyIEFkbWluIiwicHJvZHVjdF9pZCI6MTQsImlzX2FkbWluIjpudWxsLCJleHRlcm5hbF9jb2RlIjpudWxsfSx7InJvbGVfaWQiOjI4NiwibmFtZSI6IkluZGV4IERlYWxlciBBZG1pbiBVc2VyIiwicHJvZHVjdF9pZCI6MTEsImlzX2FkbWluIjpudWxsLCJleHRlcm5hbF9jb2RlIjpudWxsfV0sImV4cCI6MTc0OTQ5MTQ0Nn0.e7UC6IGJE5NVkYeSLo6qgIyC4ct_J1T6rcYKLk2mB_k","stipe_user_created":true,"isDealex":true}))
    loadJsFile('https://plugin-stage.netsolapp.io/bundle.js')

  }, [])

  return (
    <div className='main_wrapper'>
      <PagesRoutes />
    </div>
  );
}

export default App;
