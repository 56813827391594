import { ArrowForwardIos, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import img1 from '../../assets/images/car-lucid.png'

import gtrImage from '../../assets/images/x7g-01.png';


function DigitalCheckoutFooter(props) {
    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(!showA);
    return (
        <>
            <div className='po_footer'>
                <div className='po_footer_inner d-flex align-items-center justify-content-between'>
                    <div className='pof_item pe-3'>
                        <span><img src={gtrImage} alt="#"></img> </span>
                    </div>
                    <div className='pof_est_payment pe-3'>
                        <span className="acc_title mb-1 capitalize">Estimated Payment</span>
                        <h3 className='content-2--bold'>$ 2,496.89 <small className='regular'>/ month</small></h3>
                    </div>
                    {/* <div className='pof_iconBtn me-auto custom_toast_topCnter'>
                        <Button onClick={toggleShowA} variant="outline-secondary ps-2 pe-2"><KeyboardArrowUp className='d-block' /></Button>
                        <Toast show={showA} onClose={toggleShowA} position='top-center'>
                            <Toast.Header>
                                <strong className="me-auto"></strong>
                            </Toast.Header>
                            <Toast.Body>

                                <div className="pof_et_time_toast pt-5 pe-3 ps-3 pb-3">


                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">MSRP</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">$48,670</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">BMW “Road Home” Incentive</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">-$500</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">Cash Down</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">-$5,000</span>
                                        </div>
                                    </div>
                                    <div className="filter_options_divider mt-1 mb-3"></div>

                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">Trade-in Value</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">-$5,000</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">Incentives & Discount</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">-$750</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2"> Additional Accessories</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">$152</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">Protection & Coverage</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">$1,200</span>
                                        </div>
                                    </div>

                                    <div className="filter_options_divider mt-1 mb-3"></div>

                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">Dealer Adjustments</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">$2,000</span>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">Dealer-Installed Accessories</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">$2,000</span>
                                        </div>
                                    </div>
                                    <div className="filter_options_divider mt-1 mb-3"></div>

                                    <div className='row'>
                                        <div className='col-8 pb-1'>
                                            <span className="label-2 mb-0 me-2">Taxes & Fees (Zip: 10003)</span>
                                        </div>
                                        <div className='col-4 pb-1 text-end'>
                                            <span className="label-2 mb-0">$1,500</span>
                                        </div>
                                    </div>
                                    <div className="filter_options_divider mt-1 mb-3"></div>

                                    <div className='row'>
                                        <div className='col-7 pb-1'>
                                            <span className="content-2--bold d-block mb-0 me-2">Monthly Payment</span>
                                            <span className="label-2 mb-1 d-block capitalize">Estimated Payment</span>
                                        </div>
                                        <div className='col-5 pb-1 text-end'>
                                            <span className="content-2--bold mb-0 me-0">$585 <small className="regular">/ Month</small></span>
                                            <span className="label-2 mb-1 capitalize d-block">$54,670</span>
                                        </div>
                                    </div>

                                </div>

                            </Toast.Body>
                        </Toast>
                    </div> */}
                    <p className='ms-auto pe-3'> <span className="label-2 capitalize"></span></p>
                    <div className="pof_nextbtn">
                        <Link to={props?.pageUrl} className={props.disabled ? 'disabled' : ''}>
                            <Button variant="primary">Continue <ArrowForwardIos /></Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalCheckoutFooter;