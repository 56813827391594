import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ChatBubbleOutlineOutlined } from '@mui/icons-material';
import FinalizeInperson from '../../Components/Models/FinalizeInperson';
import { Link } from 'react-router-dom';
import Logo from '../../Components/Logo';
import userImage from '../../assets/images/user3.png';

function CheckoutSideNav() {
    const location = useLocation()
    const [showModal3, setShowModal3] = useState(false);
    const handleCloseModal3 = () => setShowModal3(false);
    const handleShowModal3 = () => setShowModal3(true);
    return (
        <>
            <div className="checkout_sidenav">
                <div className="header">
                    <div className="header_inner d-flex justify-content-between align-items-center">
                        <Logo />
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <div className="checkout_logo">
                                <h2 className="content-2--bold">BMW of Manhattan</h2>
                                <small className="acc_title bold">SECURE CHECKOUT</small>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="welcome_guest">
                    <div className="checkout_logo">
                        <h2 className="acc_title marginBottom0">WELCOME, GUEST</h2>
                        <Link className="signin_text">Sign In</Link>
                    </div>
                </div> */}

                <div className="welcome_guest after_login justify-content-between">
                    <div className="checkout_logo">
                        <h2 className="acc_title marginBottom0">SIGNED IN AS</h2>
                        <div className="signin_text">{localStorage.getItem("email")}</div>
                    </div>
                </div>

                <div className="checkout_menu">
                    <ul>
                        <li className={`d-flex align-items-center`}>
                            <Link to="/DigitalCheckout" className="header_location">
                                <i className='success-checkmark'></i>
                                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 1L2.2 3L1 2" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4 6.6L2.2 8.6L1 7.6" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4 12.2L2.2 14.2L1 13.2" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7 8H16" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7 2.4H16" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7 13.6H16" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Build Your Deal
                            </Link>
                        </li>

                        <li className={`d-flex align-items-center ${location.pathname.includes("/FinancingPage") ? 'active' : ''}`}>
                            <Link to="/FinancingPage" className="header_location">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 8C19 8.53043 18.7893 9.03914 18.4142 9.41421C18.0391 9.78929 17.5304 10 17 10" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7 7C7.77724 6.34221 8.63277 6 9.5 6C10.3672 6 11.2228 6.34221 12 7" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.5 9C5.40111 9 5.30444 9.02932 5.22222 9.08427C5.13999 9.13921 5.0759 9.2173 5.03806 9.30866C5.00022 9.40002 4.99031 9.50056 5.00961 9.59755C5.0289 9.69454 5.07652 9.78363 5.14645 9.85355C5.21637 9.92348 5.30546 9.9711 5.40246 9.99039C5.49945 10.0097 5.59998 9.99978 5.69134 9.96194C5.7827 9.9241 5.86079 9.86001 5.91573 9.77779C5.97068 9.69556 6 9.59889 6 9.5C6 9.36739 5.94732 9.24021 5.85355 9.14645C5.75978 9.05268 5.63261 9 5.5 9Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.86667 4.64077C5.90338 3.00462 3.46154 3 3.46154 3L3.97518 6.37231C3.30353 7.0585 2.80545 7.87777 2.51795 8.76923H1V13.3846H3.04308C3.46798 14.083 4.02714 14.7017 4.69231 15.2092V17.4231C4.69231 17.5761 4.75714 17.7228 4.87255 17.831C4.98796 17.9392 5.14448 18 5.30769 18H6.53846C6.70167 18 6.8582 17.9392 6.9736 17.831C7.08901 17.7228 7.15385 17.5761 7.15385 17.4231V16.4685C8.75092 16.9691 10.4779 16.9721 12.0769 16.4769V17.4231C12.0769 17.5761 12.1418 17.7228 12.2572 17.831C12.3726 17.9392 12.5291 18 12.6923 18H13.9231C14.0863 18 14.2428 17.9392 14.3582 17.831C14.4736 17.7228 14.5385 17.5761 14.5385 17.4231V15.2208C15.2976 14.6552 15.9125 13.9379 16.3384 13.1212C16.7642 12.3045 16.9902 11.409 17 10.5C17 5.50462 11.2154 3.27769 6.86667 4.64077Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Financing
                            </Link>
                        </li>

                        <li className="d-flex align-items-center">
                            <Link className="header_location">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M16.9333 4H3.06667C2.47756 4 2 4.44772 2 5V15C2 15.5523 2.47756 16 3.06667 16H16.9333C17.5224 16 18 15.5523 18 15V5C18 4.44772 17.5224 4 16.9333 4Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 7H18" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.8667 14H11.6" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.2889 14H7.15556" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Deposit & Reservation
                            </Link>
                        </li>

                        <li className={`d-flex align-items-center  ${location.pathname.includes("/Insurance") ? 'active' : ''}`}>
                            <Link to="/Insurance" className="header_location">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M3 8.5154V3H17V8.5154C17 10.2587 16.4764 11.9611 15.4982 13.3982C14.5201 14.8352 13.1333 15.9395 11.5206 16.5654L10.7732 16.8551C10.2755 17.0483 9.72448 17.0483 9.22677 16.8551L8.47938 16.5654C6.86674 15.9395 5.47994 14.8352 4.50178 13.3982C3.52361 11.9611 3.00001 10.2587 3 8.5154Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14 6L8.14705 11.8529L6 9.70589" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Insurance
                            </Link>
                        </li>

                        <li className={`d-flex align-items-center  ${location.pathname.includes("/Contracting") ? 'active' : ''}`}>
                            <Link to="/Contracting" className="header_location">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M12 16V12.846C12 12.6216 12.0891 12.4065 12.2478 12.2478C12.4065 12.0891 12.6216 12 12.846 12H16" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.8 3H3.93333C3.6858 3 3.4484 3.09833 3.27337 3.27337C3.09833 3.4484 3 3.6858 3 3.93333V16.0667C3 16.3142 3.09833 16.5516 3.27337 16.7266C3.4484 16.9017 3.6858 17 3.93333 17H11.0136C11.261 16.9999 11.4982 16.9017 11.6732 16.7268L16.7268 11.6732C16.9017 11.4982 16.9999 11.261 17 11.0136V5.8" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.0094 5.06718L9.59613 11.4808L7.45455 11.9091L7.88287 9.7674L14.2962 3.35382C14.5228 3.12727 14.8301 3 15.1505 3C15.4709 3 15.7782 3.12727 16.0048 3.35382L16.0094 3.35896C16.1217 3.47105 16.2108 3.60418 16.2716 3.75074C16.3324 3.8973 16.3636 4.0544 16.3636 4.21307C16.3636 4.37173 16.3324 4.52884 16.2716 4.6754C16.2108 4.82195 16.1217 4.95509 16.0094 5.06718Z" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
                                    <line x1="13.1535" y1="4.04645" x2="15.2535" y2="6.14645" stroke="#262626" />
                                </svg>
                                Contracting
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="talk_to_manhattan">
                    <div className="d-flex justify-content-center">
                        <div className="text-center">
                            <h2 className="acc_title marginBottom0">TALK TO BMW of Manhattan</h2>
                            <div className="chatBoxSec">
                                <ul>
                                    <li className="d-flex align-items-center">
                                        <Link className="d-flex align-items-center header_location">
                                            <i className="has_status_icon me-2">
                                                <small className="status_tag"></small>
                                                <ChatBubbleOutlineOutlined className="d-block me-1" />
                                            </i>
                                            <span>Online Support</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link onClick={handleShowModal3} className="header_location d-flex align-items-center">
                                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M3 17H5.17568M5.17568 17H17V3H5.17568V17ZM9.57432 16.7586V13.1379H12.8378V16.7586" stroke="black" />
                                                <path d="M6.76923 5.8H9.1923" stroke="black" />
                                                <path d="M6.76923 8.88H9.1923" stroke="black" />
                                                <path d="M12.6923 5.8H15.1154" stroke="black" />
                                                <path d="M12.6923 8.88H15.1154" stroke="black" />
                                            </svg>
                                            <span>Finalize In-Person</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <div className="backButton">
                        <Link to={'/ProductDetails'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                                <path d="M10.247 9.06547C10.2877 9.10612 10.3199 9.15437 10.3419 9.20748C10.3639 9.26059 10.3752 9.31751 10.3752 9.375C10.3752 9.43248 10.3639 9.48941 10.3419 9.54252C10.3199 9.59563 10.2877 9.64388 10.247 9.68453C10.2064 9.72518 10.1581 9.75742 10.105 9.77942C10.0519 9.80142 9.99499 9.81274 9.9375 9.81274C9.88002 9.81274 9.82309 9.80142 9.76998 9.77942C9.71687 9.75742 9.66862 9.72518 9.62797 9.68453L5.25297 5.30953C5.21229 5.2689 5.18002 5.22065 5.15801 5.16754C5.13599 5.11442 5.12466 5.05749 5.12466 5C5.12466 4.9425 5.13599 4.88557 5.15801 4.83246C5.18002 4.77935 5.21229 4.7311 5.25297 4.69047L9.62797 0.315468C9.71006 0.233375 9.8214 0.187256 9.9375 0.187256C10.0536 0.187256 10.1649 0.233375 10.247 0.315468C10.3291 0.397561 10.3752 0.508902 10.3752 0.624999C10.3752 0.741096 10.3291 0.852438 10.247 0.93453L6.18102 5L10.247 9.06547ZM1.80602 5L5.87203 0.93453C5.95413 0.852438 6.00024 0.741096 6.00024 0.624999C6.00024 0.508902 5.95413 0.397561 5.87203 0.315468C5.78994 0.233375 5.6786 0.187256 5.5625 0.187256C5.4464 0.187256 5.33506 0.233375 5.25297 0.315468L0.87797 4.69047C0.837292 4.7311 0.805023 4.77935 0.783006 4.83246C0.760989 4.88557 0.749657 4.9425 0.749657 5C0.749657 5.05749 0.760989 5.11442 0.783006 5.16754C0.805023 5.22065 0.837292 5.2689 0.87797 5.30953L5.25297 9.68453C5.29362 9.72518 5.34187 9.75742 5.39498 9.77942C5.44809 9.80142 5.50502 9.81274 5.5625 9.81274C5.61999 9.81274 5.67691 9.80142 5.73002 9.77942C5.78313 9.75742 5.83138 9.72518 5.87203 9.68453C5.91268 9.64388 5.94492 9.59563 5.96692 9.54252C5.98892 9.48941 6.00024 9.43248 6.00024 9.375C6.00024 9.31751 5.98892 9.26059 5.96692 9.20748C5.94492 9.15437 5.91268 9.10612 5.87203 9.06547L1.80602 5Z" fill="black" />
                            </svg>
                        </Link>
                    </div>

                </div>

            </div>
            <FinalizeInperson showModal={showModal3} handleCloseModal={handleCloseModal3} />
        </>
    );
};

export default CheckoutSideNav;