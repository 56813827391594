import React from "react";
import { Link } from "react-router-dom";
import { PersonOutlined, RoomOutlined, ShoppingCartOutlined, SearchOutlined, KeyboardArrowDownOutlined, ChatBubbleOutlineOutlined } from "@mui/icons-material";

function HeaderInfo(props) {
    const initials = localStorage.getItem('initials');
    return (
        <div className="header_info">
            <ul className="header_info_inner d-flex justify-content-end align-items-center">
                {
                    props?.onlineSupport ?
                        <li className="me-4 hi_online">
                            <Link className="d-flex align-items-center header_location">
                                <i className="has_status_icon me-1">
                                    <small className="status_tag"></small>
                                  <ChatBubbleOutlineOutlined className="d-block me-1" />
                                </i>
                                <span>Online Support</span>
                                <KeyboardArrowDownOutlined className="d-block" />
                            </Link>
                        </li>
                        :
                        ''
                }
                <li className="hi_location">
                    <Link className="d-flex align-items-center header_location">
                        <RoomOutlined className="d-block me-1" />
                        BMW of Manhattan
                    </Link>
                </li>
                <li className="ms-4">
                    <Link className="d-block">
                        {
                            (initials ?? '').length > 0
                            ? <div className="d-block header_search_circle">{initials}</div>
                            : <PersonOutlined className="d-block" />
                        }
                    </Link>
                </li>
                <li className="ms-4">
                    <Link> <ShoppingCartOutlined className="d-block" /></Link>
                </li>
                <li className="ms-4">
                    <Link className="header_search_circle"> <SearchOutlined className="d-block" /></Link>
                </li>
            </ul>
        </div>
    )
}

export default HeaderInfo;