import {Button} from 'react-bootstrap';
import {useAddEmploymentDetails, useUpdateEmploymentDetails} from "../../hooks";
import * as yup from 'yup'
import {isEmpty} from '../../utilities';
import {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {Masked} from '@upscopeio/react';


const INITIAL_EMPLOYMENT_VALUES = {
  employment_type: "",
  name: "",
  phone: "",
  occupation: "",
  annual_employment_income: "",
  annual_other_income: "",
  source_of_other_income: "",
  address: {
    address_line_1: "",
    address_line_2: "",
    address_type: "Mailing",
    state_name: "",
    zip_code: "",
    city: ""
  }
}
const VALIDATION_SCHEMA = yup.object({
  name: yup
      .string()
      .nullable()
      .required('Employer Name is required'),
  employment_type: yup
      .string()
      .nullable()
      .required('Employment Type is required'),
  occupation: yup
      .string()
      .nullable()
      .required('Occupation is required'),
  annual_other_income: yup
      .string()
      .nullable()
      .required('Annual  Income is required'),
  address: yup.object
  ({
    address_line_1: yup
        .string()
        .nullable()
        .required('Street No is required'),
  })

})

const EmploymentDetails = ({
                             activeState4, activeState5, setActiveState5, customerRefId, employmentData
                           }) => {

  const {mutate: employmentDetails} = useAddEmploymentDetails()
  const {mutate: updateEmploymentDetails} = useUpdateEmploymentDetails()
  const {
    control,
    formState: {errors},
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: INITIAL_EMPLOYMENT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
    mode: 'all'
  })

  useEffect(() => {
    if (employmentData) {
      setActiveState5('state5Saved')
      reset({
        employment_type: employmentData?.employment_type,
        name: employmentData?.name,
        employed_since: employmentData?.employed_since,
        occupation: employmentData?.occupation,
        annual_employment_income: employmentData?.annual_employment_income,
        annual_other_income: employmentData?.annual_other_income,
        source_of_other_income: employmentData?.source_of_other_income,
        address: {
          address_line_1: employmentData?.address?.address_line_1,
          address_line_2: employmentData?.address?.address_line_2,
          // address_type: "Mailing",
          state_name: employmentData?.address?.state_name,
          zip_code: employmentData?.address?.zip_code,
          city: employmentData?.address?.city,
        }
      })
    }
  }, [employmentData])

  const SUBMIT_HANDLER = (formData) => {
    setActiveState5("state5Saved")
    const payload = {
      ...formData,
      address: {
        ...formData.address,
        address_type: "Employment"
      },

      reference_id: customerRefId,
      // reference_id: customerRefId,
      // customer_addresses: []
    }
    if (isEmpty(employmentData)) {
      employmentDetails(payload)
    } else if (!isEmpty(employmentData)) {
      updateEmploymentDetails(payload)

    }
  }
  return (
      <div className={activeState4.id === 'state4Saved' ? `rd_section mb-5 section-05` : 'd-none'}>
        <div className='row'>
          <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
            <h3 className='headline-6 light'><span className="text-secondary pe-3">05</span>Provide your Employment &
              Income Details</h3>
            <Button className="px-3 py-2" onClick={(e) => {
              setActiveState5("state5Notsaved")
            }} variant="outline-secondary" type>Edit</Button>
          </div>

          <form onSubmit={handleSubmit(SUBMIT_HANDLER)}>
            <div className="col-md-9">

              <div className={activeState5.id === 'state5Notsaved' ? `bd_box border input-Form-main mb-4` : ' d-none'}
                   id="state5Notsaved">
                <p className='content-3--regular-grey mb-4'>Share your employment information and sources of income.</p>

                <div className='row mb-3'>
                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> Occupation: </span>
                    <Masked>
                      <Controller
                          name={'occupation'}
                          control={control}
                          render={({field}) => (
                              <><input
                                  {...field}
                                  className={`form-control ${errors?.occupation?.message?.toString() ? 'error-field' : ''}`}
                                  id='occupation' name='occupation' type="text" placeholder=''/>
                                <div className='error'>{errors?.occupation?.message?.toString()}</div>
                              </>

                          )}
                      />
                    </Masked>
                    <div className='error'> {errors?.occupation?.message?.toString()}</div>
                    {/* <Form.Control type="text" /> */}
                  </div>

                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> Employment Type: </span>
                    <Masked>
                      <Controller
                          name={'employment_type'}
                          control={control}
                          render={({field}) => (
                              <><input
                                  {...field}
                                  className={`form-control ${errors?.employment_type?.message?.toString() ? 'error-field' : ''}`}
                                  id='employment_type' name='employment_type' type="text" placeholder=''/>
                                <div className='error'>{errors?.employment_type?.message?.toString()}</div>
                              </>
                          )}
                      />
                    </Masked>
                  </div>

                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> Annual Income: </span>
                    <Masked>
                      <Controller
                          name={'annual_other_income'}
                          control={control}
                          render={({field}) => (
                              <><input
                                  {...field}
                                  className={`form-control ${errors?.annual_other_income?.message?.toString()} ? 'error-field' : ''}`}

                                  id='annual_other_income' name='annual_other_income' type="number" placeholder=''/>
                                <div className='error'>{errors?.annual_other_income?.message?.toString()}</div>
                              </>
                          )}
                      />
                    </Masked>
                  </div>

                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> Employed Since: </span>
                    <Masked>
                      <Controller
                          name={'employed_since'}
                          control={control}
                          render={({field}) => (
                              <input
                                  {...field}
                                  className="form-control" id='employed_since' name='employed_since' type="date"
                                  placeholder=''/>
                          )}
                      />
                    </Masked>
                    {/* <Form.Control type="text" /> */}
                  </div>

                </div>

                <hr></hr>

                <div className='row mb-3'>
                  <div className='col-md-12 pb-3'>
                    <span className='acc_title mb-2 bold'> Employer Name: </span>
                    <Masked>
                      <Controller
                          name={'name'}
                          control={control}
                          render={({field}) => (
                              <><input {...field}
                                       className={`form-control ${errors?.name?.message ? 'error-field' : ''}`}
                                       id='name' name='name' type="text" placeholder=''/>
                                <div className='error'>{errors?.name?.message}</div>
                              </>
                          )}
                      />
                    </Masked>
                    {/* <Form.Control type="text" /> */}
                  </div>

                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> Address Line 1 </span>
                    <Masked>
                      <Controller
                          name={'address.address_line_1'}
                          control={control}
                          render={({field}) => (
                              <><input {...field}
                                       className={`form-control ${errors?.address?.address_line_1?.message ? 'error-field' : ''}`}
                                       id='address.address_line_1' name='address.address_line_1' type="text"
                                       placeholder=''/>
                                <div className='error'>{errors?.address?.address_line_1?.message}</div>
                              </>
                          )}
                      />
                    </Masked>
                    {/* <Form.Control type="text" /> */}
                  </div>

                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> Address Line 2 : </span>
                    <Masked>
                    <Controller
                        name={'address.address_line_2'}
                        control={control}
                        render={({field}) => (
                            <><input {...field}
                                     className={`form-control ${errors?.address?.address_line_2?.message ? 'error-field' : ''}`}
                                     id='address.address_line_2' name='address.address_line_2' type="text"
                                     placeholder=''/>
                              <div className='error'>{errors?.address?.address_line_2?.message}</div>
                            </>
                        )}
                    />
                    </Masked>
                    {/* <div className='error'><ErrorMessage name='residence' /></div> */}

                    {/* <Form.Control type="text" /> */}
                  </div>

                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> City: </span>
                    <Masked>
                    <Controller
                        name={'address.city'}
                        control={control}
                        render={({field}) => (
                            <input {...field} className="form-control" id='address.city' name='address.city' type="text"
                                   placeholder=''/>
                        )}
                    />
                    </Masked>
                  </div>
                  <Masked>
                  <div className='col-md-4 pb-3'>
                    <span className='acc_title mb-2 bold'> Region: </span>
                    <Controller
                        name={'address.state_name'}
                        control={control}
                        render={({field}) => (
                            <> <input
                                {...field}
                                className={`form-control ${errors?.address?.state_name?.message ? 'error-field' : ''}`}
                                id='address.state_name' name='address.state_name' type="text" placeholder=''/>
                              <div className='error'>{errors?.address?.state_name?.message}</div>
                            </>
                        )}
                    />

                  </div>
                  </Masked>

                  <div className='col-md-4 pb-3'>
                    <span className='acc_title mb-2 bold'> Post Code: </span>
                    <Masked>
                    <Controller
                        name={'address.zip_code'}
                        control={control}
                        render={({field}) => (
                            <input {...field} className="form-control" id='address.zip_code' name='address.zip_code'
                                   type="text" placeholder=''/>
                        )}
                    />
                    </Masked>
                    {/* <Form.Control type="text" /> */}
                  </div>

                </div>

                <hr></hr>

                <div className='row mb-3 '>
                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> Annual Salary: </span>
                    <Masked>
                    <Controller
                        name={'annual_employment_income'}
                        control={control}
                        render={({field}) => (
                            <input {...field} className="form-control"
                                   id='annual_employment_income' name='annual_employment_income' type="number"
                                   placeholder=''/>
                        )}
                    />
                    {/* <Form.Control type="text" /> */}
                    </Masked>
                  </div>

                  <div className='col-md-6 pb-3'>
                    <span className='acc_title mb-2 bold'> Other Annual Income: </span>
                    <Masked>
                    <Controller
                        name={'source_of_other_income'}
                        control={control}
                        render={({field}) => (
                            <input {...field} className="form-control" id='source_of_other_income'
                                   name='source_of_other_income' type="text" placeholder=''/>
                        )}
                    />
                    </Masked>
                    {/* <Form.Control type="text" /> */}
                  </div>

                </div>

                <div className='d-flex align-items-end justify-content-start'>
                  <Button variant='dark' type='submit'>Save & Continue</Button>
                </div>
              </div>

              <div className={activeState5.id === 'state5Saved' ? `bd_box border` : 'd-none'} id="state5Saved">
                <div className='bd_your_lease_sec d-flex align-items-center'>

                  <div className='bd_yl_text pe-5 w-50'>
                    <div className='mb-3'>
                      <div className='row'>
                        <div className='content-3--regular-grey bold'>Employer Name</div>
                        <Masked>
                        <div className='content-3--regular-grey'>{employmentData?.name}</div>
                        </Masked>
                      </div>
                    </div>

                    <div className='mb-3'>
                      <div className='row'>
                        <div className='content-3--regular-grey bold'>Occupation</div>
                        <Masked>
                        <div className='content-3--regular-grey'>{employmentData?.occupation}</div>
                        </Masked>
                      </div>
                    </div>

                    <div className='mb-3'>
                      <div className='row'>
                        <div className='content-3--regular-grey bold'>Annual Salary</div>
                        <Masked>
                        <div className='content-3--regular-grey'>{employmentData?.annual_employment_income}</div>
                        </Masked>
                      </div>
                    </div>

                  </div>
                  <div className='bd_yl_text ps-5 w-50'>
                    <div className='mb-3'>
                      <div className='row'>
                        <div className='content-3--regular-grey bold'>Employer Address</div>
                        <Masked>
                        <div className='content-3--regular-grey'>{employmentData?.address?.address_line_1}</div>
                        </Masked>
                      </div>
                    </div>

                    <div className='mb-3'>
                      <div className='row'>
                        <div className='content-3--regular-grey bold'>Employment Type</div>
                        <Masked>
                        <div className='content-3--regular-grey'>{employmentData?.employment_type}</div>
                        </Masked>
                      </div>
                    </div>

                    <div className='mb-3'>
                      <div className='row'>
                        <div className='content-3--regular-grey bold'>Employed Since</div>
                        <Masked>
                        <div className='content-3--regular-grey'>{employmentData?.employed_since}</div>
                        </Masked>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
  )
}
export default EmploymentDetails
