import { Button, Tab, Tabs } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { Logout } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/lucid-maintenance3.webp';
import img3 from '../../assets/images/lucid-maintenance1.jpg';
import img2 from '../../assets/images/lucid-maintenance2.jpg';
import itemlogo from '../../assets/images/mcp_img_logo.png';
import img4 from '../../assets/images/maintainence program.jpg'
import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import PlusToggleBtn from '../../ScreensUi/DigitalCheckout/PlusToggleBtn';
import { useLocation } from 'react-router-dom';
import FarabiNew from '../../Components/Farabi/indexNew'

const MaintenanceAndServicePlans = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')
    const link = `${location.pathname}${location.search}`

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);

    // const handleStep = (e) => {
    //     setIsStep(e);
    //     console.log(isStep);
    //     if (isStep === 'step2_finalQuestion') {
    //         setIsDisabledNext(false);
    //     }
    // };



    return (
        <div>
            <div className="digital_checkout_wrapper">

                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">

                        <div className="po_header pb-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>
                        <div className='iad_content po_content_height'>
                            <div className='d-flex justify-content-between'>
                                <div className='po_headlines'>
                                    <h3 className='headline-5 regular pb-1 d-flex align-items-center'>Maintenance & Service Plans</h3>
                                </div>
                            </div>


                            <div className='iad_cards_list'>
                                <div className='row pb-4 pt-4'>
                                    <div className='col-lg-4 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img1} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                {/* <p className='d-flex  justify-content-end pb-2'><img width='132' src={itemlogo} alt='#'></img></p> */}
                                                <h3 className='content-2--bold pb-1 d-flex justify-content-between pe-4'>Maintenance Program
                                                    <small>$ 160 / Month</small>
                                                </h3>
                                                <p className='label-2 pb-2'><small>3 Years; 36,000 Miles</small></p>
                                                <p className='label-2 pb-1'>Covers all factory-recommended maintenance services such as new brake pads and discs, battery servicing and other critical parts.</p>


                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img4} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                {/* <p className='d-flex  justify-content-end  pb-2'><img width='132' src={itemlogo} alt='#'></img></p> */}
                                                <h3 className='content-2--bold pb-1 d-flex justify-content-between pe-4'>Maintenance Program
                                                    <small>$ 440/ Month</small>
                                                </h3>
                                                <p className='label-2 pb-2'><small>10 Years; 100,000 Miles</small></p>
                                                <p className='label-2 pb-1'>Covers all factory-recommended maintenance services such as new brake pads and discs, battery servicing and other critical parts.</p>


                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img3} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                {/* <p className='d-flex  justify-content-end pb-2'><img width='132' src={itemlogo} alt='#'></img></p> */}
                                                <h3 className='content-2--bold pb-1 d-flex justify-content-between pe-4'>Full Pit Crew
                                                    <small>$ 600/ Month</small>
                                                </h3>
                                                <p className='label-2 pb-2'><small>10 Years; 100,000 Miles</small></p>
                                                <p className='label-2 pb-1'>Covers all factory-recommended maintenance services such as new brake pads and discs, battery servicing and other critical parts.</p>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <FarabiNew />
                        <DigitalCheckoutFooter pageUrl={`/WarrantyAndProtectionPlansVehicleCoverage?orderId=${orderID}&customerRefId=${customerRefId}`} />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default MaintenanceAndServicePlans;