import { Button, Form, OverlayTrigger } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { ArrowForwardIos, Clear, LightbulbOutlined, Logout, Menu, MenuBook, Videocam } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Masked } from '@upscopeio/react';



import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

const DeliveryAndPickup = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);
    const [isDisabledNext, setIsDisabledNext] = useState(true);


    const [selectedOption, setSelectedOption] = useState('');
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        // console.log(selectedOption)
    };

    //
    const [activeLinkId, setActiveLinkId] = useState('');
    const handleLinkClick = (linkId) => {
        setActiveLinkId(linkId);
    };

    const daysArr = [
        { id: 1, day: 'MON', date: '13' },
        { id: 2, day: 'TUE', date: '14' },
        { id: 3, day: 'WED', date: '15' },
        { id: 4, day: 'TH', date: '16' },
        { id: 5, day: 'FRI', date: '17' },
        { id: 6, day: 'SAT', date: '18' },
    ];



    const [activeTimeSlot, setTimeSlot] = useState('');
    const handleTimeSlot = (linkId) => {
        setTimeSlot(linkId);
        setIsDisabledNext(false);
    };
    const timeslotsArr = [
        { id: 1, time: '8:00 AM - 9:30 AM' },
        { id: 2, time: '3:00 PM - 4:30 PM' },
        { id: 3, time: '10:00 AM - 11:30 AM' },
        { id: 4, time: '6:00 AM - 07:30 AM' },

    ];

    const [isClassAdded, setIsClassAdded] = useState(false);
    const toggleClass = () => {
        setIsClassAdded(!isClassAdded);
    };


    return (
        <div>
            <div className="digital_checkout_wrapper">

                <div className="d-flex">
                    <div className={`checkoutSideNavMain ${isClassAdded ? 'open' : ''}`}>
                        <CheckoutSideNav />
                        <Button className="dcoCloseBtn" variant="outline-secondary" onClick={toggleClass}><Clear className="m-0" /></Button>
                    </div>
                    <div className="checkout_rightContent pb-0">
                        <div className="po_header pb-3">
                            <div className="po_header_inner">
                                <Button className="dcoMenuBtn" variant="outline-secondary px-2" onClick={toggleClass}><Menu className="m-0" /></Button>

                                <div className="d-flex justify-content-end align-items-center">
                                    <Link to={'/DigitalCheckout'}>
                                        <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='iad_content po_content_height'>
                            <div className='d-flex justify-content-between'>
                                <div className='po_headlines'>
                                    <h3 className='headline-5 regular pb-1 d-flex align-items-center'>DELIVERY & PICKUP</h3>
                                    <p className='label-2 regular pb-3'>Tell us how you'd like to receive your vehicle</p>
                                </div>
                            </div>



                            <div className='dap_content'>
                                <div className="rd_section mb-5">
                                    <div className='row'>
                                        <div className='col-lg-9 d-flex align-items-center pb-3 justify-content-between'>
                                            <h3 className='headline-6 light'><span className="text-secondary pe-3">01</span>Select Your Delivery of Pick-Up Preferences</h3>
                                            <Button className="px-3 py-2" variant="outline-secondary">Edit</Button>
                                        </div>
                                        <div className="col-lg-9">

                                            <div className='bd_box border mb-4'>
                                                <p className='content-3--regular-grey mb-3'>Select the option that applies to your purchase.</p>
                                                <div className='bd_your_lease_sec d-flex align-items-center pb-4'>
                                                    <div className={`bd_box border dap_radio_box margin-right-3 ${selectedOption === 'option1' ? 'active' : ''}`}>
                                                        <div className='pe-0 border-bottom pb-3 mb-3'>
                                                            <label className='d-flex align-items-center content-3 bold black'>
                                                                <Form.Check type="radio" name='Delivery' aria-label="radio 1" value="option1"
                                                                    checked={selectedOption === 'option1'}
                                                                    onChange={handleOptionChange} />
                                                                <span className='ps-2'>Delivery</span>
                                                                {/* <strong className='ms-auto label-2 uppercase'>+$150</strong> */}
                                                            </label>
                                                        </div>
                                                        <p className='content-3--regular-grey ps-2 pe-2'>YOUR ADDRESS</p>
                                                        <Masked>
                                                        <p className='content-3--regular-grey ps-2 pe-2'>822 Sarbonne Rd</p>
                                                        <p className='content-3--regular-grey ps-2 pe-2'>Los Angeles, California 90077</p>
                                                        </Masked>
                                                        <div className='pe-0 border-bottom mb-3 pt-3'></div>
                                                        <p className='ms-auto label-2'>Available as soon as May 13</p>
                                                    </div>

                                                    <div className={`bd_box border dap_radio_box ${selectedOption === 'option2' ? 'active' : ''}`}>
                                                        <div className='pe-0 border-bottom pb-3 mb-3'>
                                                            <label className='d-flex align-items-center content-3 bold black'>
                                                                <Form.Check type="radio" name='Delivery' aria-label="radio 1" value="option2"
                                                                    checked={selectedOption === 'option2'}
                                                                    onChange={handleOptionChange} />
                                                                <span className='ps-2'>Pick-Up</span>
                                                                {/* <strong className='ms-auto label-2 uppercase'>FREE</strong> */}
                                                            </label>
                                                        </div>
                                                        <p className='content-3--regular-grey ps-2 pe-2'>YOUR PRIMARY DEALER</p>
                                                        <p className='content-3--regular-grey ps-2 pe-2'>555 W 57th St</p>
                                                        <p className='content-3--regular-grey ps-2 pe-2'>New York, New York 10019</p>
                                                        <div className='pe-0 border-bottom mb-3 pt-3'></div>
                                                        <p className='ms-auto label-2'>Available as soon as May 13</p>
                                                    </div>
                                                </div>

                                                <div className=''>
                                                    <div className='fp_date'>
                                                        {
                                                            selectedOption === 'option1' ?
                                                                <>
                                                                    <div className='mb-2'>
                                                                        <p className='content-3--regular-grey mb-3 d-flex align-items-center flex-wrap'>When would you like us to deliver your new vehicle?  <span className='msrp_tip ms-2'>

                                                                        </span></p>
                                                                    </div>
                                                                    <div className='fp_date_box position-relative border mb-3'>
                                                                        <div className='fp_date_box_inner'>
                                                                            <ul className='d-flex'>
                                                                                {daysArr.map((link) => (
                                                                                    <li>
                                                                                        <button key={link.id}
                                                                                            //  className={`fp_date_btn ${activeLinkId.includes(link.id) ? 'active' : ''}`}
                                                                                            className={`fp_date_btn ${activeLinkId === link.id ? 'active' : ''}`}
                                                                                            onClick={() => handleLinkClick(link.id)}
                                                                                        >
                                                                                            <span> {link.day} </span>
                                                                                            {link.date}

                                                                                        </button>
                                                                                    </li>
                                                                                ))}

                                                                            </ul>
                                                                        </div>
                                                                        <div className='fp_date_next'>
                                                                            <button type='button' className='fp_date_next_btn border'><ArrowForwardIos /></button>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        activeLinkId !== '' ?
                                                                            <>
                                                                                <div className='mb-2'>
                                                                                    <p className='content-3--regular-grey mb-3 d-flex align-items-center'>Available timeslots for Monday, May 13th <span className='msrp_tip ms-2'>

                                                                                    </span></p>
                                                                                </div>
                                                                                <div className='fp_time_main mb-4'>
                                                                                    <ul>

                                                                                        {timeslotsArr.map((link) => (
                                                                                            <li>
                                                                                                <button key={link.id}
                                                                                                    className={`fp_time_btn border ${activeTimeSlot === link.id ? 'active' : ''}`}
                                                                                                    onClick={() => handleTimeSlot(link.id)}
                                                                                                >

                                                                                                    {link.time}

                                                                                                </button>
                                                                                            </li>
                                                                                        ))}

                                                                                    </ul>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            ''
                                                                    }
                                                                </>
                                                                :
                                                                ''
                                                        }




                                                    </div>
                                                </div>

                                                <div className='d-flex align-items-end justify-content-start'>
                                                    <Button disabled={activeTimeSlot === '' ? true : false} variant='dark'>Confirm Delivery Preferences</Button>
                                                </div>
                                            </div>



                                        </div>
                                        {/* <div className="col-lg-3">
                                            <p className='bd_alert_text mt-4 content-3--regular-grey'>
                                                <LightbulbOutlined />
                                                If you'd like to lower your monthly payment, try adjusting the down payment or lease length.
                                            </p>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <DigitalCheckoutFooter pageUrl={`/ReviewYourDeal?orderId=${orderID}&customerRefId=${customerRefId}`} disabled={isDisabledNext} />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default DeliveryAndPickup;
