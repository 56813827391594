import React, { useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer/Footer";
import HeaderHomeShopingHUB from "../../Components/HeaderHomeShopingHub";
import { useGetQuotations } from '../../hooks'
import InventoryItem from "./InventoryItem";
import { Link } from 'react-router-dom';
import { KeyboardArrowDownOutlined, EventNoteOutlined, CompareArrows, MoreVert, FiberManualRecord, KeyboardArrowRight, MapsHomeWorkOutlined, CalendarTodayOutlined, MarkChatUnreadOutlined, AccessTimeOutlined, ArrowOutwardOutlined } from '@mui/icons-material';
import carimg from '../../assets/images/BMW-SOC24-i5-GlobalNav.avif';
import { Tooltip } from "@mui/material";
import { OverlayTrigger } from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import { useParams } from 'react-router-dom';
import houseimg from '../../assets/images/Frame38.png';
import cubeicon from '../../assets/images/cube.svg';

// const columnFilters = `reference_id=ref-U-W0000GCP`

function HomeShopingHub() {
  const { customerRefId } = useParams();
  const columnFilters = `email=${localStorage.getItem("email")}&finance_type=${'Finance'}`
  const { data: customerQuotations, isLoading } = useGetQuotations(columnFilters)

  const [showC, setShowC] = useState(false);
  const toggleShowC = () => {
    setShowC(!showC);
  };

  return (
    <>
      <Header />
      <HeaderHomeShopingHUB />
      <div className="mg_shoppinghub-wrapper">
        <div className="d-block w-100 pb-3">
        <div className="mg_auto-content pb-5">
          <div className="results_infoheader d-flex align-items-baseline justify-content-between flex-wrap pb-4 inventory_result_content_inner">
            <div className="col p-4 mg_sh-wrapper-content text-nowrap">
              <div className="d-flex align-items-center position-relative">
                <span className="content-2--regular-grey">Showing (3)</span>
                <h2 className="content-2--bold px-2"> BMW of Manhattan</h2>
                {/* <Link onClick={toggleShowC} className="header_location filtersH_link border"><KeyboardArrowDownOutlined /> </Link> */}
                <Toast show={showC} onClose={toggleShowC} className="mg_homeshoping-toast3">
                  <Toast.Header className="mg_toast-close">
                    <strong className="me-auto"></strong>
                  </Toast.Header>
                  <Toast.Body>
                    <div className=''>
                      <div className='d-flex flex-column p-3'>
                        <div className=''>
                          <div className='d-flex flex-column align-items-start'>
                            <img src={houseimg} alt='#'></img>
                          </div>
                          <div className="">
                            <div className='d-flex flex-column mb-3 content-2 border-bottom pb-2 mg_toast3-text'>
                              <ul className="content-3">
                                <li> <MapsHomeWorkOutlined className="mg_toast3-icon" /> 555 W 57th,New York,NY</li>
                                <li className="px-3">10019</li>
                                <li className="px-3">[212]586-2269</li>
                                <li className="px-3">www.bmwnyc.com <ArrowOutwardOutlined className="mg_toast3-icon" /> </li>
                              </ul>
                              <div className="py-2">
                                <button className="product_list_btn ps-3 pe-3 w-100 btn btn-secondary">Switch Dealer</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className='d-flex flex-column'>
                            <ul>
                              <li className="label-2 bold p-2 border-bottom mb-2"> <CalendarTodayOutlined className="mg_toast3-icon" /> Schedule a Visit or Test Drive</li>
                              <li className="label-2 bold p-2 border-bottom mb-2"> <MarkChatUnreadOutlined className="mg_toast3-icon" /> <span className="text-success">Available to Chat</span>  Until 6 PM</li>
                              <li className="label-2 bold p-2"> <AccessTimeOutlined className="mg_toast3-icon" /> <span className="text-success">Now Open Close</span> at 6 PM <KeyboardArrowDownOutlined /> </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Toast.Body>
                </Toast>
              </div>
              <p className="content-3 py-2">This estimate includes taxes and other fees</p>
            </div>
            <div className="mg_shoppinghub-left col d-flex justify-content-end align-items-center flex-wrap">
              <ul className="d-flex justify-content-end align-items-center">
                <li className="me-4">
                  <Link className="d-flex align-items-center header_location filtersH_link text-nowrap">
                    <span className="label-2 bold header_location filtersH_link">Find Similar Vehicles</span>
                  </Link>
                </li>
                <li className="me-4">
                  <Link className="d-flex align-items-center header_location filtersH_link text-nowrap">
                    <p>Sorted By:</p>
                    <span className="label-2 bold header_location filtersH_link">Data Added</span>
                    <KeyboardArrowDownOutlined className="d-block" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="row g-3">
              {
                isLoading
                  ? <p>Loading...</p>
                  : customerQuotations?.map((quotation, index) => {
                    return <InventoryItem key={index} quotation={quotation} />
                  })
              }
            </div>
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default HomeShopingHub;